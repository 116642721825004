import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import { Link } from 'react-router-dom';
import { findArticleDisclaimerText } from '../buyPlansUtil';
import { submitTosDataTrial } from '../../providers/EstoreProvider';
import { TosStatusOption } from '../../providers/GarageProvider';
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert } from 'reactstrap';
import MarketingProducts from '../components/MarketingProducts';
import AutoLabelCheckbox from '../components/AutoLabelCheckbox';
import { BuyPlanPages } from '../';

export default class DataTrial extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    alertMessage: null,
    processing: false,
    articleDisclaimer: null,
    consents: [],
    unClickedLinks: [],
    openMustReadTermsModal: false
  };

  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    let plan = this.context?.selectedPlan;

    if (!plan) {
      const marketingConfigurationId = this.props.match?.params?.marketingConfigurationId;
      const { additionalPlans, selectPlanFromList } = this.context;

      const [, category, subCategory] = this.props.match?.url?.split('/') || [];
      plan = selectPlanFromList(additionalPlans, category, subCategory, marketingConfigurationId);
    }

    const marketingConfiguration = this.context.marketingConfigurationHash?.[plan?.marketingConfigurationId];
    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);
    const consentCheckbox = marketingConfiguration?.consentCheckbox;
    let consents = [];
    if (Array.isArray(consentCheckbox)) {
      consents = AutoLabelCheckbox.parseValuesFromConsentCheckbox(consentCheckbox);
    }

    const unClickedLinks = AutoLabelCheckbox.getInnerUrls(consents);

    this.setState({ articleDisclaimer, consents, unClickedLinks });

    window.scrollTo(0, 0);
  };

  clearAlerts = () => this.setState({ alertMessage: null });

  getAlerts = () => {
    if (this.state.alertMessage) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alertMessage}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  onClickHyperLink = (href) => {
    const unClickedLinks = this.state.unClickedLinks.filter((link) => link !== href);

    this.setState({ unClickedLinks });
  };

  submit = async (e) => {
    e.preventDefault();
    const form = e.target.form;

    if (form?.checkValidity() === false) {
      form?.reportValidity();

      return;
    }

    if (form.querySelector('input[disabled]')) {
      this.setState({ openMustReadTermsModal: true });

      return;
    }

    this.setState({ processing: true });

    try {
      let response = await submitTosDataTrial(TosStatusOption.ACCEPT);

      if (response?.data?.errorCode) {
        let alertMessage;
        alertMessage = response?.data?.errorDescription;
        this.setState({ alertMessage });
      } else {
        this.context.redirectTo(BuyPlanPages.Home);
      }
    } catch (ex) {
      console.log('Error while submitting data trail ::' + ex);
    } finally {
      this.setState({ processing: false });
    }
  };

  closeModalMustReadTerms = () => {
    this.setState({ openMustReadTermsModal: false });
  };

  render() {
    const {
      translator,
      selectedPlan: plan,
      marketingConfigurationHash,
      contentURL,
      selectPlan,
      showDisclaimer,
      userCountry
    } = this.context;
    const consents = this.state.consents || [];
    const marketingConfiguration = marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};

    const additionalDisclaimers =
      Array.isArray(marketingConfiguration?.additionalDisclaimerList) &&
      marketingConfiguration?.additionalDisclaimerList.map((ad) => ad.disclaimerText);

    return (
      <>
        <div role='main' id='main-content' className='py-4'>
          <div className='ml-4 position-absolute'>
            <Link to='/' className='linkBack' role='button' onClick={() => selectPlan(null)}>
              {translator.t('buyplans.button-label.back-to-your-plans')}
            </Link>
          </div>
          <form className='container d-flex flex-column align-items-center mt-4 pb-5' onSubmit={() => false}>
            <h1 className='font-weight-light'>
              {marketingConfiguration?.title}
              {this.state.articleDisclaimer && (
                <sup
                  className='cwp-disclaimer ml-2 mainTitlePosition'
                  tabIndex={0}
                  role='button'
                  onClick={(e) =>
                    showDisclaimer(
                      this.state.articleDisclaimer,
                      marketingConfiguration.articleDisclaimerNumber,
                      e.currentTarget
                    )
                  }
                >
                  {marketingConfiguration?.articleDisclaimerNumber}
                </sup>
              )}
            </h1>
            <section className='text-muted my-2'>
              <article>
                {marketingConfiguration?.description}
                {marketingConfiguration?.additionalDescription && (
                  <>
                    <br />
                    {marketingConfiguration?.additionalDescription}
                  </>
                )}
              </article>
            </section>
            {this.getAlerts()}
            <img
              src={'' + contentURL + marketingConfiguration?.summary?.providerLogo}
              alt={marketingConfiguration?.summary?.providerName}
              className='my-3 mr-auto'
            />
            <article className='articleBox p-3 text-justify'>{additionalDisclaimers}</article>

            {Array.isArray(marketingConfiguration?.consentCheckbox) &&
              consents.map((cc) => (
                <div key={`ConsentCheckbox-${cc.checkboxNumber}`} className='my-3 d-flex'>
                  <AutoLabelCheckbox
                    value={cc}
                    onClickHyperLink={(href) => this.onClickHyperLink(href)}
                    unClickedLinks={this.state.unClickedLinks}
                    disableCheckboxUntilAllLinksClicked={userCountry === 'CA'}
                    translator={translator}
                  />
                </div>
              ))}

            <button
              type='button'
              className='cwp cwpPrimary mt-4'
              disabled={this.state.processing}
              onClick={this.submit}
            >
              {translator.t('buyplans_datatrial_submit_acceptance')}
            </button>
          </form>
          {plan && (
            <MarketingProducts
              plan={plan}
              marketingConfiguration={marketingConfiguration}
              contentURL={contentURL}
              showDisclaimer={showDisclaimer}
            />
          )}

          <Modal
            isOpen={this.state.openMustReadTermsModal}
            centered={true}
            className='carnet-sg-modal text-center'
            aria-live='polite'
            onHide={() => this.closeModalMustReadTerms()}
          >
            <ModalHeader className='mx-auto'>
              <span className='h1'>{translator.t('buyplans.label.must_read_terms')}</span>
            </ModalHeader>
            <ModalBody>{marketingConfiguration?.consentCheckbox?.consentCheckboxWarning}</ModalBody>
            <ModalFooter className='d-flex flex-column align-items-center'>
              <button type='button' className='modal-primary-btn w-100' onClick={this.closeModalMustReadTerms}>
                {translator.t('button.ok')}
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}
