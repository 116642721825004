import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import { findVehicle, findEnrollmentProcedure } from '../providers/SelfEnrollmentProvider';
import Spinner from '../Spinner';
import defaultVehicleImage from '../../../assets/images/defaultVehicleImage.png';
import { findIn } from '../../../util/utilityMethods';
import { VW_CARNET_URL } from '../../../constants/carnet_constants';
import { setTitle } from '../../../providers/documentTitleProvider';

class SearchVehicle extends React.Component {
  state = {
    processing: false,
    alert: null,
    vin: null,
    data: null,
    showAssociationExistsModal: false,
    errorCode: null
  };

  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    setTitle('document_titles.garage.vin_lookup');
  }

  clearAlerts = () => this.setState({ alert: null, errorCode: null, errorDescription: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <div className='alert alert-danger mx-3' role='alert'>
          {this.state.alert}
        </div>
      );
    }

    if (this.state.errorCode) {
      let translateKey = `errorCodes.${this.state.errorCode}`;
      let codeTranslation = this.translator.t(translateKey);
      let translationString = codeTranslation === translateKey ? '' : codeTranslation;
      return (
        <div className='alert alert-danger mx-3' role='alert'>
          {translationString || this.state.errorDescription}
        </div>
      );
    }

    return null;
  };

  setVIN = async event => {
    event.preventDefault();

    let value = event.currentTarget.value;
    value = value.replace(/[^abcdefghjklmnprstuvwxyz0-9]/gim, '');

    if (value.length !== 17) {
      this.setState({ vin: value });
      return;
    }

    this.setState(
      {
        vin: value,
        data: null,
        processing: true,
        alert: null,
        errorCode: null,
        errorDescription: null
      },
      async () => {
        try {
          let data = await findVehicle(value);

          this.setState({ data, processing: false });

          setTitle('document_titles.garage.add_vin');
        } catch (error) {
          setTitle('document_titles.garage.vin_lookup');
          const errorCode = findIn('error.error.errorCode', error);
          const errorDescription = findIn('error.error.errorDescription', error) || '';
          if (errorCode) {
            this.setState({ processing: false, errorCode, errorDescription });

            return;
          }

          this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
        }
      }
    );
  };

  onclickAddVehicle = async e => {
    e.preventDefault();

    this.setState({ processing: true, alert: null });
    try {
      const data = await findEnrollmentProcedure();
      if (data && data.enrollmentProcedure && data.enrollmentProcedure === 'NOT_APPLICABLE') {
        this.setState({
          processing: false,
          alert: this.translator.t('self_enrollment.add_vehicle.vehicle_not_equipped')
        });
        return;
      }
      //Commented as per todays discussion with Hadi, We will review and revisit back.
      /*else if (findIn('associationExists', data)) {
        this.setState({ processing: false, showAssociationExistsModal: true });
        return;
      }*/

      this.setState({ processing: false });

      this.props.onSuccess(data);
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  onBackClick = e => {
    e.preventDefault();
    window.history.back();
  };

  vehicleInfo = () => {
    if (!this.state.vin || this.state.vin.length !== 17 || !this.state.data || !this.state.data.vehicleId) return <></>;

    const imgUrl = this.state.data.representativeImgURLComplete || defaultVehicleImage;
    const vehicleName = `${this.state.data.modelYear} ${this.state.data.modelName}`;

    return (
      <>
        <div className='text-center'>
          <span className='sr-only'>{this.translator.t('self_enrollment.add_vehicle.vehicle_found')}</span>
          <img src={imgUrl} alt='' width='300' />
          <br />
          <span className='text-left d-inline-block m-auto'>
            <h2>{vehicleName}</h2>
            <strong>
              {this.translator.t('self_enrollment.add_vehicle.vin')}: {this.state.vin}
            </strong>
          </span>
          <br />
        </div>
        <button onClick={this.onclickAddVehicle} className='cwp mt-3 mb-5' disabled={this.state.processing}>
          {this.translator.t('self_enrollment.add_vehicle.add_vehicle')}
        </button>
      </>
    );
  };

  render () {
    return (
      <>
        <div className='enrollmentFullWidthLeft'>
          <span
            className='cwpBack'
            onClick={this.onBackClick}
            tabIndex={0}
            role='button'
            onKeyPress={e => (e.key === ' ' || e.key === 'Enter' ? this.onBackClick(e) : e.preventDefault())}
          >
            {this.translator.t('button.back')}
          </span>
        </div>
        <h1 className='font-weight-normal'>
          {this.translator.t('self_enrollment.add_vehicle.add_a')}{' '}
          <strong>{this.translator.t('self_enrollment.add_vehicle.vehicle')}</strong>
        </h1>
        {this.getAlerts()}
        <div className='form-group mt-5'>
          <label htmlFor='txtVIN' className=' cwp maxWidth300 d-block mx-auto text-left'>
            {this.translator.t('self_enrollment.add_vehicle.vin')}
          </label>
          <input
            id='txtVIN'
            type='text'
            className='cwp inputIconSearch'
            placeholder={this.translator.t('self_enrollment.add_vehicle.enter_vin')}
            maxLength='17'
            minLength='17'
            onChange={this.setVIN}
            value={this.state.vin}
          />
        </div>
        <br />
        <Spinner hidden={!this.state.processing} />
        {this.vehicleInfo()}

        <Modal isOpen={this.state.showAssociationExistsModal} className='carnet-sg-modal tosDeclinationConfirmation'>
          <ModalHeader>{this.translator.t('self_enrollment.add_vehicle.additional_driver')}</ModalHeader>
          <ModalBody>
            {this.translator.t('self_enrollment.add_vehicle.additional_driver_message1')}
            <a href={VW_CARNET_URL} target='_blank' rel='noopener noreferrer'>
              {this.translator.t('self_enrollment.add_vehicle.additional_driver_message2')}
            </a>
            <br />
            {this.translator.t('self_enrollment.add_vehicle.additional_driver_message3')}
          </ModalBody>
          <ModalFooter>
            <div>
              <Button
                className='modal-primary-btn'
                color='modal_background_color'
                onClick={() => this.setState({ showAssociationExistsModal: !this.state.showAssociationExistsModal })}
              >
                {this.translator.t('self_enrollment.add_vehicle.okay')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default SearchVehicle;
