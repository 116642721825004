import { createLogic } from 'redux-logic';
import axios from 'axios';
import { GET_CAR_NICK_NAME, GET_CAR_NICK_NAME_SUCCESS, GET_CAR_NICK_NAME_FAILURE } from '../../actions/types';

let dataProvider = axios;

let requestConfig = {
  method: 'GET',
  url: `/carnickname`,
  headers: {
    accept: 'application/json'
  }
};

const getCarNickNameLogic = createLogic({
  type: GET_CAR_NICK_NAME,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: GET_CAR_NICK_NAME_SUCCESS,
    failType: GET_CAR_NICK_NAME_FAILURE
  },

  process ({ action }) {
    console.log('getCarNickName is processing an action >>>');
    console.log('type: ' + action.type);
    console.log('payload: ' + JSON.stringify(action.payload));

    return dataProvider(requestConfig).then(response => {
      console.log('got response for getCarNickName GET request >>> ');
      console.log(JSON.stringify(response.data, null, 1));
      return response.data;
    });
  }
});

export default [getCarNickNameLogic];
