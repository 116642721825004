import { GET_CAR_NICK_NAME, GET_CAR_NICK_NAME_SUCCESS, GET_CAR_NICK_NAME_FAILURE } from '../../actions/types';

const initalState = {
  carNickNameData: null
};

const carNickNameReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_CAR_NICK_NAME:
      newState = { ...initalState };
      return newState;

    case GET_CAR_NICK_NAME_SUCCESS:
      let { data } = responseData;
      newState['carNickNameData'] = data || null;

      return newState;
    case GET_CAR_NICK_NAME_FAILURE:
      newState['carNickNameData'] = null;
      return newState;
    default:
      return newState;
  }
};

export default carNickNameReducer;
