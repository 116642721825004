import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, Table, Row, Col, Button } from 'reactstrap';
import { dateAndTimeFormat } from '../../../util/i18n/localeUtils';
import axios from 'axios';
import TripStopsMap from './TripStopsMap';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { fuelEconomylitersToKM, kmToMiles, litersToGallons } from './TripStatsUtil';
import { isNonEmptyObject } from '../../../util/utilityMethods';
import { isUserPrimary } from '../../../providers/userRolesProvider';

class TripStatsHistory extends Component {
  constructor () {
    super();
    this.state = {
      tripDetails: {},
      tripStopsModal: false,
      deleteTrips: false
    };
    this.translator = getLocaleTranslator();
  }

  showTripStops = tripId => {
    axios.get(`rts/trip?tripId=${tripId}`).then(res => {
      this.setState({ tripDetails: res.data.data });
    });
    this.setState({ tripStopsModal: !this.state.tripStopsModal });
  };

  deleteAllTrips = () => {
    axios.delete(`/rts/removetrips`).then(res => {
      this.setState({ deleteTrips: !this.state.deleteTrips });
    });
  };

  toggleTripStopsModal = () => {
    this.setState({ tripStopsModal: !this.state.tripStopsModal });
  };

  toggleDeleteTrips = () => {
    this.setState({ deleteTrips: !this.state.deleteTrips });
  };

  render () {
    let translator = getLocaleTranslator();
    const remoteTripDetails = this.props.remoteTripDetails;
    const tripStops = this.state.tripDetails;
    let userIsFromCountryUS = this.props.userCountry === 'US';

    return (
      <div>
        <Row>
          <Col>
            <h2 className='rts-history-title'>{this.translator.t('trips.trip_stats_history')}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {isUserPrimary() && (
              <div
                className='rts-delete-history'
                onClick={this.toggleDeleteTrips}
                onKeyPress={e => {
                  if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                    this.toggleDeleteTrips();
                  }
                }}
                tabIndex='0'
                role='button'
              >
                {this.translator.t('trips.delete_all_history_label')}
              </div>
            )}
          </Col>
        </Row>
        <Table striped className='rts-history-table'>
          <thead>
            <tr className='d-none d-md-table-row'>
              <th className='d-none d-md-table-cell'>{this.translator.t('trips.start_label')}</th>
              <th className='d-none d-md-table-cell'>{this.translator.t('trips.end_label')}</th>
              <th className='d-none d-md-table-cell'>{this.translator.t('trips.stops_label')}</th>
              <th className='d-none d-md-table-cell'>{this.translator.t('trips.total_distance_driven_label')}</th>
              <th className='d-none d-md-table-cell'>{this.translator.t('trips.average_mph_or_kmph_label')}</th>
              <th className='d-none d-md-table-cell'>{this.translator.t('trips.average_mpg_or_kml_label')}</th>
              <th className='d-sm-table-cell d-md-none'></th>
            </tr>
          </thead>
          <tbody>
            {isNonEmptyObject(remoteTripDetails) &&
              remoteTripDetails.tripDetails.map((tripHistory, index) => (
                <>
                  <tr key={index}>
                    <td className='d-none d-md-table-cell'>
                      <Button
                        color='link'
                        className='rts-tripid px-0'
                        onClick={() => this.showTripStops(tripHistory.tripId)}
                      >
                        {dateAndTimeFormat(tripHistory.startTime)}
                      </Button>
                    </td>
                    <td className='d-none d-md-table-cell'>{dateAndTimeFormat(tripHistory.endTime)}</td>
                    <td className='d-none d-md-table-cell'>{tripHistory.tripStopCount}</td>
                    <td className='d-none d-md-table-cell'>
                      {userIsFromCountryUS
                        ? kmToMiles(tripHistory.tripSummary.distanceDriven)
                        : tripHistory.tripSummary.distanceDriven}
                      &nbsp;{this.translator.t('trips.miles_or_Kilometers_label')}
                    </td>
                    <td className='d-none d-md-table-cell'>
                      {userIsFromCountryUS
                        ? kmToMiles(tripHistory.tripSummary.avgSpeed)
                        : tripHistory.tripSummary.avgSpeed}
                    </td>
                    <td className='d-none d-md-table-cell'>
                      {userIsFromCountryUS
                        ? litersToGallons(tripHistory.tripSummary.fuelEconomy)
                        : fuelEconomylitersToKM(tripHistory.tripSummary.fuelEconomy)}
                    </td>
                    <td className='d-sm-table-cell d-md-none'>
                      <div className='d-flex justify-content-between'>
                        <strong>{this.translator.t('trips.start_label')}</strong>
                        <span>
                          <Button
                            color='link'
                            className='carnetLink p-0'
                            onClick={() => this.showTripStops(tripHistory.tripId)}
                          >
                            {dateAndTimeFormat(tripHistory.startTime)}
                          </Button>
                        </span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <strong>{this.translator.t('trips.end_label')}</strong>
                        <span>{dateAndTimeFormat(tripHistory.endTime)}</span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <strong>{this.translator.t('trips.stops_label')}</strong>
                        <span>{tripHistory.tripStopCount}</span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <strong>{this.translator.t('trips.total_distance_driven_label')}</strong>
                        <span>
                          {userIsFromCountryUS
                            ? kmToMiles(tripHistory.tripSummary.distanceDriven)
                            : tripHistory.tripSummary.distanceDriven}
                          &nbsp;{this.translator.t('trips.miles_or_Kilometers_label')}
                        </span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <strong>{this.translator.t('trips.average_mph_or_kmph_label')}</strong>
                        <span>
                          {userIsFromCountryUS
                            ? kmToMiles(tripHistory.tripSummary.avgSpeed)
                            : tripHistory.tripSummary.avgSpeed}
                        </span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <strong>{this.translator.t('trips.average_mpg_or_kml_label')}</strong>
                        <span>
                          {userIsFromCountryUS
                            ? litersToGallons(tripHistory.tripSummary.fuelEconomy)
                            : fuelEconomylitersToKM(tripHistory.tripSummary.fuelEconomy)}
                        </span>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </Table>
        <Modal
          isOpen={this.state.tripStopsModal}
          toggle={this.toggleTripStopsModal}
          centered={true}
          className='rts-stops-modal'
          aria-label={`${this.translator.t('trips.trip_history_date')} ${dateAndTimeFormat(tripStops.startTime)}`}
        >
          <ModalBody>
            <div>
              <span
                className='rts-modal-exit'
                onClick={this.toggleTripStopsModal}
                onKeyPress={e => {
                  if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                    this.toggleTripStopsModal();
                  }
                }}
                tabIndex='0'
                role='button'
              />
              <Row>
                <Col md={8}>
                  <TripStopsMap tripStops={tripStops} />
                </Col>
                <Col>
                  <div className='rts-modal-trip'>
                    <div className='rts-modal-time'>
                      {dateAndTimeFormat(tripStops.startTime)}-{dateAndTimeFormat(tripStops.endTime)}
                    </div>
                    {/*<div className='rts-modal-date'>{FormatDate(tripStops.date, 'ddd, MMM.DD, YYYY')}</div> */}
                    {isNonEmptyObject(tripStops) && (
                      <div>
                        <div className='rts-modal-distance'>
                          {userIsFromCountryUS
                            ? kmToMiles(tripStops.tripSummary.distanceDriven)
                            : tripStops.tripSummary.distanceDriven}
                          &nbsp;
                          {this.translator.t('trips.miles_or_Kilometers_label')}
                        </div>
                        <div>
                          <span className='rts-modal-average'>
                            {this.translator.t('trips.average_mph_or_kmph_label')}
                          </span>
                          <span className='rts-modal-average'>
                            {this.translator.t('trips.average_mpg_or_kml_label')}
                          </span>
                        </div>
                        <div>
                          <span className='rts-modal-mpg'>
                            {userIsFromCountryUS
                              ? kmToMiles(tripStops.tripSummary.avgSpeed)
                              : tripStops.tripSummary.avgSpeed}
                          </span>
                          <span className='rts-modal-mpg'>
                            {userIsFromCountryUS
                              ? litersToGallons(tripStops.tripSummary.fuelEconomy)
                              : fuelEconomylitersToKM(tripStops.tripSummary.fuelEconomy)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.deleteTrips} toggle={this.toggleDeleteTrips} centered={true}>
          <ModalBody>
            <span
              className='rts-modal-exit'
              onClick={this.toggleDeleteTrips}
              onKeyPress={this.toggleDeleteTrips}
              tabIndex='0'
              role='button'
              aria-label={translator.t('close')}
            />
            <p>{this.translator.t('trips.delete_confirm_text')}</p>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggleDeleteTrips}>
              {this.translator.t('trips.cancel_label')}
            </Button>
            <Button color='primary' onClick={this.deleteAllTrips}>
              {this.translator.t('trips.ok_label')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default TripStatsHistory;
