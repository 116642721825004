import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { formulateTheUsernameResetUrl, getDefaultLocales } from '../providers/AccountAndSecurityProvider';
import {
  redirectBrowserTo,
  isNonEmptyObject,
  isNullOrEmptyObject,
  isBlankString,
  parseAndLocaleFormatDBFormattedDate
} from '../../../util/utilityMethods';
import { formatPhoneNumber } from '../CarnetHome';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Loader from '../common/loader';
import ToolTip from '../common/ToolTip';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';

class PersonalInfo extends React.Component {
  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps && isNonEmptyObject(nextProps.accountDetails)) {
      return { loadingDetails: false };
    }
    return null;
  }

  constructor (props) {
    super(props);
    this.phonesList = [];
    let passedInLocale = this.props.usersLocale;

    this.state = {
      editingField: null,
      liveUserInfo: null,
      displayUsernameResetModal: false,
      usersLocale: passedInLocale,
      editingCommLanguage: false,
      communicationLanguage: '',
      displayCommunicationLanguageName: '',
      userLocales: [],
      loadingData: true,
      loadingDetails: true
    };
    this.getActiveEditingPane = this.getActiveEditingPane.bind(this);
    this.translator = getLocaleTranslator();
  }

  convertLocalesDataToDropdownValues = localesList => {
    let retval = [];
    if (!localesList || localesList.length === 0) {
      return retval;
    }
    return localesList.map(element => {
      const dropdownItem = { code: element.localeCode, value: element.description };
      return dropdownItem;
    });
  };

  componentDidMount () {
    this.setCommLanguageDropdown();
    setTitle('document_titles.profile_page');
  }

  componentDidUpdate (prevProps = {}, prevState = {}) {
    // prop changed, let us sync up local state
    let communicationLanguageHashChanged =
      this.props.communicationLanguage && this.props.communicationLanguage !== prevProps.communicationLanguage;
    let localeHasChanged = this.props.locale && this.props.locale !== prevProps.locale;
    if (communicationLanguageHashChanged || localeHasChanged) {
      this.setCommLanguageDropdown();
    }
    if (prevState.loadingData && !this.state.loadingData && this.props.focus) {
      try {
        document.getElementById('firstActiveFocus').focus();
      } catch (err) {
        console.log('element not found for autofocus');
      }
    }
  }

  setCommLanguageDropdown = () => {
    let nextState = {};
    getDefaultLocales()
      .then(data => {
        if (data) {
          let localesArr = (data && data.data && data.data.locales) || [];
          const supportedLocales = this.convertLocalesDataToDropdownValues(localesArr);
          nextState['userLocales'] = supportedLocales;
          nextState['communicationLanguage'] = this.props.communicationLanguage || 'en-US';
          let langThing = supportedLocales.filter(item => item.code === nextState['communicationLanguage'])[0] || {};
          let displayCommunicationLanguageName = langThing['value'] || 'English';
          nextState['displayCommunicationLanguageName'] = this.getTranslatedCommLangName(
            displayCommunicationLanguageName
          );
        }
      })
      .catch(err => {
        console.log('Failed request to get default Locales! :' + JSON.stringify(err));
      })
      .finally(() => {
        nextState['loadingData'] = false;
        this.setState(nextState);
      });
  };

  handleExitEditMode = () => {
    this.setState({ editingField: null });
  };

  saveChangedCommLangPref = nxLocaleCode => {
    let mutableCustomerProfile = JSON.parse(JSON.stringify(this.props.accountData));
    const prevLocaleCode = mutableCustomerProfile.localeCode;
    if (nxLocaleCode === prevLocaleCode) {
      return; //there has been no change
    }
    mutableCustomerProfile.localeCode = nxLocaleCode;
    this.props.saveHandler(mutableCustomerProfile);
  };

  toggleCommLanguageEditing = () => {
    this.setState({
      editingCommLanguage: !this.state.editingCommLanguage
    });
  };

  saveCommunicationLanguageSelection = () => {
    let delta = { localeCode: this.state.communicationLanguage };
    this.props.refreshHandler(delta, null);
    this.saveChangedCommLangPref(this.state.communicationLanguage);
    this.setState({
      usersLocale: this.state.communicationLanguage,
      editingCommLanguage: !this.state.editingCommLanguage
    });
  };

  editField (reference) {
    console.log('editField() invoked for ref: ' + reference);
    this.props.editingCallback(reference);
  }

  setUsernameModalVisibility = visible => {
    this.setState({
      displayUsernameResetModal: visible
    });
  };

  launchTheWholeUsernameResetProcess = () => {
    let aStateValue = 'something'; //it can be a randomly-generated, unique. Doesnt really matter.
    const currentEmail = this.props.accountData && this.props.accountData.email;
    if (!currentEmail) {
      throw 'UsernameEditor component did not receive the necessary property: *injectedCurrentEmail* ';
    }
    let storage = window && window.localStorage;
    storage.setItem('CWP_LAST_VIEWED_PAGE', '/#/editUsername');

    //For security reasons: I moved the whole formation of the redirect url used below OUT OF the frontent
    //It has been moved into Hapi-side code:
    formulateTheUsernameResetUrl()
      .then(idkUrl => {
        redirectBrowserTo(idkUrl);
      })
      .catch(err => {
        console.error('Error thrown in getting the redirectURL: ' + err);
        return;
      });
  };

  getActiveEditingPane () {
    if (this.state.editingField === null) {
      return <span></span>;
    }
  }

  readDriverRole = (contextUsersId, allAssocUsersList) => {
    if (!contextUsersId || allAssocUsersList.length === 0) {
      return this.UNKNOWN_ROLE;
    }
    let contextEntries = allAssocUsersList.filter(record => {
      return record.userId === contextUsersId;
    });

    if (contextEntries.length === 0) {
      return this.UNKNOWN_ROLE;
    }
    let contextRoles = contextEntries[0].roles;
    if (!contextRoles) {
      return this.UNKNOWN_ROLE;
    }
    let primaryRole = contextRoles.filter(role => {
      return role === 'PU';
    });
    if (primaryRole && primaryRole.length > 0) {
      return this.PRIMARY_ROLE;
    }
    let secondaryRole = contextRoles.filter(role => {
      return role === 'SU' || role === 'GU';
    });
    if (secondaryRole && secondaryRole.length > 0) {
      return this.SECONDARY_ROLE;
    }
    return this.UNKNOWN_ROLE;
  };

  getTranslatedCommLangName = serviceLangName => {
    let translationLang = serviceLangName || 'English';
    translationLang = translationLang.toLowerCase();
    return this.translator.t('acctmgmt_label_commlang_' + translationLang);
  };

  render () {
    this.UKNOWN_ROLE = this.translator.t('acctmgmt_drroles_unk');
    this.PRIMARY_ROLE = this.translator.t('acctmgmt_drroles_prime');
    this.SECONDARY_ROLE = this.translator.t('acctmgmt_drroles_secu');
    let details = this.props.accountDetails || {};
    let driverRole = '';

    let customerProfile = details.customer || {};
    if (isNonEmptyObject(details)) {
      const passedInAssocUsers = details.allAssociatedUsers || [];
      if (passedInAssocUsers.length === 0 || isNullOrEmptyObject(customerProfile)) {
        driverRole = this.UNKNOWN_ROLE;
      } else {
        driverRole = this.readDriverRole(customerProfile.userId, passedInAssocUsers);
      }
    }
    const i18nSaveLabel = this.translator.t('save_btn_camel');
    const i18nChangeLabel = this.translator.t('acctmgmt_change_label');
    let addressObj = customerProfile.address || {};
    let phonesList = customerProfile.phones || [];
    let emergencyContact = this.props.contact || (details.customer && details.customer.emergencyContact);
    let usersLocaleCode = this.state.usersLocale || '';
    let formattedBirthDate = '';
    if (!isBlankString(this.props.birthDate)) {
      // console.log('PI got the raw birthdate: ' + this.props.birthDate);
      formattedBirthDate = parseAndLocaleFormatDBFormattedDate(this.props.birthDate, usersLocaleCode) || '';
    }

    const isEditingCommLang = this.state.editingCommLanguage;
    const commlang_advisory = this.translator.t('acctmgmt_tooltip_commlang');

    return this.state.loadingData === true || this.state.loadingDetails === true ? (
      <div id='acctmgmt-contentpane'>
        <Loader />
      </div>
    ) : (
      <div className='acctmgmt-contentpane'>
        <div id='ghost-wrapper'>
          <div className='contentpane-title'>
            <h2 id='firstActiveFocus' tabIndex={-1} className='h6'>
              {this.translator.t('acctmgmt_pi_pane_title')}
            </h2>
          </div>
          <div>
            <Row>
              <Col>
                <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_pi_field_label_name')}</span>
              </Col>
              <Col md='6'>
                <span ref='pi-name-field' className='carnet-theme-field-value'>
                  {customerProfile.firstName === null ? '' : customerProfile.firstName}&nbsp;
                  {customerProfile.lastName === null ? '' : customerProfile.lastName}
                </span>
              </Col>
              <Col>
                <button
                  className='btn btn-link carnetLinkLight'
                  type='button'
                  onClick={e => this.editField('pi-name-field')}
                >
                  {this.translator.t('acctmgmt_pi_edit_link_label')}
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_pi_field_label_userid')}</span>
              </Col>
              <Col md='5'>
                <span className='carnet-theme-field-value'>
                  {customerProfile.email === null ? '' : customerProfile.email}
                </span>
              </Col>
              <Col md='1'></Col>
              <Col>
                <button
                  className='btn btn-link carnetLinkLight'
                  type='button'
                  onClick={e => this.setUsernameModalVisibility(true)}
                >
                  {this.translator.t('acctmgmt_pi_edit_link_label')}
                </button>
                {/* Conditional Display the Username Reset Modal */}
                <Modal
                  isOpen={this.state.displayUsernameResetModal}
                  className='carnet-sg-modal'
                  labelledBy='headerIdChangeEmail'
                  autoFocus={false}
                  aria-live='polite'
                >
                  <div class='modal-header'>
                    <h2 id='headerIdChangeEmail' className='h5 font-weight-bold modal-title'>
                      {this.translator.t('acctmgmt_change_userid')}
                    </h2>
                  </div>
                  <ModalBody>{this.translator.t('acctmgmt_instrux_change_userid')}</ModalBody>
                  <ModalFooter className='design-deviation-footer px-0 mx-auto'>
                    <Button
                      className='modal-primary-btn cwpFocusVisible my-3'
                      color='modal-text-color'
                      onClick={this.launchTheWholeUsernameResetProcess}
                    >
                      {this.translator.t('tos_carnet.continue')}
                    </Button>
                    <Button
                      className='modal-secondary-btn cwpFocusVisible'
                      color='modal_background_color'
                      autoFocus={true}
                      onClick={() => {
                        this.setUsernameModalVisibility(false);
                      }}
                    >
                      {this.translator.t('cancel_btn_camel')}
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
            {/*  DRIVER ROLE FIELD: Only for ATC and WCT  */}
            {this.props.tsp && this.props.tsp !== 'VZT' && (
              <Row>
                <Col>
                  <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_pi_field_label_role')}</span>
                </Col>
                <Col md='6'>
                  <span ref='pi-name-field' className='carnet-theme-field-value'>
                    {driverRole}
                  </span>
                </Col>
                <Col></Col>
              </Row>
            )}
            <Row>
              <Col className='toolTipWrappingOuterElement'>
                <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_label_commlang')}</span>
                <ToolTip hoverText={commlang_advisory} />
              </Col>
              <Col md='6'>
                {/* Ternary JSX choice */}
                {isEditingCommLang === true && (
                  <div className='editor-diatom-field'>
                    <label htmlFor='lst-communication-language' className='editor-diatom-superscript-lbl'>
                      {this.translator.t('acctmgmt_label_lang')}
                    </label>
                    <br />
                    <select
                      id='lst-communication-language'
                      className='cwp'
                      onChange={evt => {
                        let displayCommunicationLanguageName = evt.currentTarget.value;
                        let langOb =
                          (this.state.userLocales || []).filter(
                            item => item.value === displayCommunicationLanguageName
                          )[0] || {};
                        let communicationLanguage = langOb.code || 'en-US';
                        this.setState({ communicationLanguage, displayCommunicationLanguageName });
                      }}
                      value={this.state.displayCommunicationLanguageName}
                    >
                      {(this.state.userLocales || []).map((record, index) => {
                        return (
                          <option key={'user_locales_' + index} value={record.value}>
                            {this.getTranslatedCommLangName(record.value)}
                          </option>
                        );
                      })}
                    </select>
                    <br />
                  </div>
                )}

                {!isEditingCommLang && (
                  <span ref='pi-name-field' className='carnet-theme-field-value'>
                    {this.state.displayCommunicationLanguageName ||
                      this.translator.t('acctmgmt_label_commlang_english')}
                  </span>
                )}
              </Col>
              <Col>
                <button
                  className='btn btn-link carnetLinkLight'
                  type='button'
                  onClick={e => {
                    e.preventDefault();
                    if (this.state.editingCommLanguage) {
                      // extract save method from toggleCommLanguageEditing
                      return this.saveCommunicationLanguageSelection();
                    }
                    this.toggleCommLanguageEditing();
                  }}
                >
                  {this.state.editingCommLanguage ? i18nSaveLabel : i18nChangeLabel}
                </button>
              </Col>
            </Row>

            {/* END CREATION  */}
            <Row>
              <Col>
                <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_pi_field_label_address')}</span>
              </Col>
              <Col md='6'>
                <div ref='pi-field-address' id='pi-field-address' className='carnet-theme-field-value'>
                  {isNullOrEmptyObject(addressObj) ? (
                    <pre></pre>
                  ) : (
                    <pre>
                      {addressObj.addressLine1}
                      {!isBlankString(addressObj.addressLine2) && (
                        <div>
                          <br />
                          {addressObj.addressLine2}
                        </div>
                      )}
                      <br />
                      {addressObj.city}
                      <br />
                      {addressObj.state}
                      <br />
                      {addressObj.zipCode}
                      <br />
                    </pre>
                  )}
                </div>
              </Col>
              <Col>
                <button
                  className='btn btn-link carnetLinkLight'
                  type='button'
                  onClick={e => this.editField('pi-field-address')}
                >
                  {this.translator.t('acctmgmt_pi_edit_link_label')}
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='carnet-theme-field-label'>
                  {/* Phone Number */}
                  {this.translator.t('acctmgmt_pi_field_label_phone')}
                </span>
              </Col>
              <Col md='6'>
                <div className='carnet-theme-field-value'>
                  <ul className='personal-info-fone-fields'>
                    {/* BEGIN LOOP HERE  */}
                    {phonesList.map((phone, index) => {
                      return (
                        <li key={'phoneNumber_' + index} className='pi-summary-phonenums-data' ref='pi-field-phone'>
                          {(phone.number && formatPhoneNumber(phone.number)) || ''}
                        </li>
                      );
                    })}
                    {/*  end loop here */}

                    {/* Selectively display the 'add phone' link ONLY if User has < 3 phone numbers presently */}
                    {phonesList.length < 3 && (
                      <li className='add-phone-link-item'>
                        {/* Add a Phone Number */}
                        <button
                          type='button'
                          onClick={e => this.editField('pi-field-phone')}
                          className='btn btn-link carnetLink add-phone-link'
                        >
                          &#8853; {this.translator.t('acctmgmt_pi_field_label_addphone')}
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </Col>
              <Col>
                <button
                  className='btn btn-link carnetLinkLight'
                  type='button'
                  onClick={e => this.editField('pi-field-phone')}
                >
                  {this.translator.t('acctmgmt_pi_edit_link_label')}
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='carnet-theme-field-label'>
                  {/* Birth Date */}
                  {this.translator.t('acctmgmt_pi_field_label_birthdate')}
                </span>
              </Col>
              <Col md='6'>
                <div ref='pi-field-dob' className='carnet-theme-field-value'>
                  {formattedBirthDate}
                </div>
              </Col>
              <Col>
                <button
                  className='btn btn-link carnetLinkLight'
                  type='button'
                  onClick={e => this.editField('pi-field-dob')}
                >
                  {this.translator.t('acctmgmt_pi_edit_link_label')}
                </button>
              </Col>
            </Row>
            {/* Emergency Contact   (name,phone,and email) */}
            <Row>
              <Col>
                <span className='carnet-theme-field-label'>
                  {/* Emergency Contact */}
                  {this.translator.t('acctmgmt_pi_field_label_emergency')}
                </span>
              </Col>
              <Col md='6'>
                {emergencyContact && (
                  <ul className='personal-info-multivalue-cell'>
                    <li>
                      <span id='emergency-contact-name' className='carnet-theme-field-value'>
                        {emergencyContact.name}
                      </span>
                    </li>

                    {/* BEGIN LOOP HERE  */}
                    {(emergencyContact.phones || []).map((phone, index) => {
                      return (
                        <li key={'emergency_phone_' + index}>
                          <span className='carnet-theme-field-value emergency-contact-meta'>
                            {(phone && formatPhoneNumber(phone)) || ''}
                          </span>
                        </li>
                      );
                    })}
                    <li>
                      <span className='carnet-theme-field-value emergency-contact-meta'>{emergencyContact.email}</span>
                    </li>
                  </ul>
                )}
              </Col>
              <Col>
                <button
                  className='btn btn-link carnetLinkLight'
                  type='button'
                  onClick={e => this.editField('pi-field-emergency-contact')}
                >
                  {this.translator.t('acctmgmt_pi_edit_link_label')}
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
export default PersonalInfo;
