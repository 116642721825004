import React, { useState } from 'react';
import geico from '../../../assets/images/geico.jpg';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function GeicoMsgAvailable (props) {
  const { available = true } = props;
  const translator = getLocaleTranslator();
  const [geicoMsgVisible, setGeicoMsgVisible] = useState(false);
  const toggleGeicoMsg = () => {
    setGeicoMsgVisible(!geicoMsgVisible);
  };

  const openInsuranceQuotesSite = () => {
    window.open(
      'https://www.geico.com/landingpage/go558/?soa=47862&utm_medium=partnership&utm_campaign=volkswagen-affiliate&utm_source=volkswagen'
    );
    toggleGeicoMsg();
  };

  if (available) {
    return (
      <div class='driveview-scss-insulator'>
        <div className='geicoWrapper'>
          <div className='advertisement-header'>{translator.t('driveview.geico.advertisement')}</div>
          <div className='rowgeico'>
            <div className='columngeico-right'>
              <img src={geico} alt='' width='210'></img>
            </div>
            <div class='columngeico-left'>
              <div className='geico-header'>{translator.t('driveview.geico.header-title')}</div>
              <div className='geico-subheader'>{translator.t('driveview.geico.header-title-1')}</div>
              <div className='geico-content'>
                {translator.t('driveview.geico.header-content')}
                <div className='geico-link'>
                  <button type='button' class='cwp btn btn-primary' onClick={toggleGeicoMsg}>
                    {translator.t('driveview.geico.geico-quote')}
                  </button>
                </div>
              </div>
            </div>
            <div className='disclaimer'>{translator.t('driveview.geico.geico-disclaimer')}</div>
          </div>
        </div>

        <Modal
          toggle={toggleGeicoMsg}
          centered={true}
          className='carnet-sg-modal geicoModal'
          isOpen={geicoMsgVisible}
          closeButton
          aria-live='polite'
          aria-label={translator.t('driveview.geico.geico-redirect')}
        >
          <ModalHeader>{translator.t('driveview.geico.geico-redirect')}</ModalHeader>
          <ModalBody>
            {translator.t('driveview.geico.geico-redirect-body')}
            <br />
            {translator.t('driveview.geico.geico-redirect-body-line2')}
          </ModalBody>
          <ModalFooter>
            <button className='modal-primary-btn btn btn-modal_background_color' onClick={toggleGeicoMsg}>
              {translator.t('driveview.geico.geico-cancel')}
            </button>
            <button
              type='button'
              className='modal-secondary-btn btn btn-modal-text-color'
              onClick={openInsuranceQuotesSite}
            >
              {translator.t('driveview.geico.geico-ok')}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  return <></>;
}
