import React from 'react';
import PropTypes from 'prop-types';

export default class ScrollableContent extends React.Component {
  static propTypes = {
    onScrolled: PropTypes.func
  };

  handleScroll = () => {
    const element = document.getElementById('tosscroll');
    const elementClientHeightToScroll = element.clientHeight + 5;
    const elementScrollPosition = element.scrollHeight - element.scrollTop;
    if (elementScrollPosition <= elementClientHeightToScroll) {
      this.props.onScrolled();
    }
  };

  render () {
    return (
      <article
        id='tosscroll'
        onScroll={this.handleScroll}
        className='tosUpdateHtmlContent'
        style={{ minHeight: '300px' }}
      >
        {this.props.children}
      </article>
    );
  }
}
