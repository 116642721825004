import React, { Component } from 'react';
import * as ReactBootStrap from 'react-bootstrap';
import './Navbar.scss';
import logo from '../../../assets/images/vwlogo.png';
import '../CarnetHome.scss';
import LanguageToggle from '../../language_toggle/toggle';
import LoginOut from '../loginOut/LoginOut';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

class NavBarATC extends Component {
  constructor (props) {
    super(props);
    this.state = {
      userIsPrimary: true
    };
  }

  componentDidMount () {
    const userPrimaryStatus = isUserPrimary();
    if (userPrimaryStatus === false || userPrimaryStatus === true) {
      this.setState({
        userIsPrimary: userPrimaryStatus
      });
    }
  }

  render () {
    let translator = getLocaleTranslator();
    return (
      <header className='App-ATC'>
        <ReactBootStrap.Navbar collapseOnSelect expand='lg' bg='danger' variant='dark'>
          <ReactBootStrap.Navbar.Brand>
            <a href='#buy-plans'>
              <img src={logo} alt={translator.t('text_alt_carnet_logo')} width='130'></img>
            </a>
          </ReactBootStrap.Navbar.Brand>

          <ReactBootStrap.Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <ReactBootStrap.Navbar.Collapse id='responsive-navbar-nav'>
            <ReactBootStrap.Nav className='mr-auto'>
              <ReactBootStrap.Nav.Link href='#buy-plans'>
                <span>{translator.t('buy_plans_header')}</span>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='#history'>
                <span>{translator.t('history_header')}</span>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='#vhr'>
                <span>{translator.t('vhr_header')}</span>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='#trips'>
                <span>{translator.t('trips_header')}</span>
              </ReactBootStrap.Nav.Link>
            </ReactBootStrap.Nav>
            <ReactBootStrap.Nav>
              <ReactBootStrap.Nav.Link href='#messagecenter'>
                <span>{translator.t('ms_header')}</span>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='#acct-mgmt'>
                <span>{translator.t('account_header')}</span>
              </ReactBootStrap.Nav.Link>
              <LoginOut inverted={true} />
              <LanguageToggle inverted={true} />
            </ReactBootStrap.Nav>
          </ReactBootStrap.Navbar.Collapse>
        </ReactBootStrap.Navbar>
      </header>
    );
  }
}

export default NavBarATC;
