import axios from 'axios';
import carnet_constants from '../../../constants/carnet_constants';

export const getmessageCenterInfoServiceCall = async (category = 'all', pageSize = '10', pageNo = '0') => {
  let requestConfig = {
    method: 'GET',
    url: `/messagecenter`,
    headers: {
      accept: 'application/json'
    },
    params: {
      category,
      pageNo,
      pageSize
    }
  };
  return await axios(requestConfig);
};

export const handleMessageDeleteAllServiceCall = async () => {
  let requestConfig = {
    method: 'DELETE',
    url: `/deleteAllmessagecenter`,
    headers: {
      accept: 'application/json'
    },
    params: {
      deleteAllEvent: true
    }
  };
  return await axios(requestConfig);
};

export const handleMessageDeleteServiceCall = async messageId => {
  let requestConfig = {
    method: 'DELETE',
    url: `/deletemessagecenter`,
    headers: {
      accept: 'application/json'
    },
    params: {
      deleteEvent: true,
      messageId
    }
  };

  return await axios(requestConfig);
};

export const checkMoreServiceCall = async pageSize => {
  let requestConfig = {
    method: 'GET',
    url: `/showMoreMessages`,
    headers: {
      accept: 'application/json'
    },
    params: {
      pageSize
    }
  };
  return await axios(requestConfig);
};

export const viewMessageServiceCall = async messageId => {
  let requestConfig = {
    method: 'GET',
    url: `/viewmessages`,
    headers: {
      accept: 'application/json'
    },
    params: {
      messageId: messageId
    }
  };

  return await axios(requestConfig);
};

export const searchMessagesServiceCall = async searchText => {
  let pageNo = 1;
  let pageSize = 10;

  let requestConfig = {
    method: 'GET',
    url: `/searchmessage`,
    headers: {
      accept: 'application/json'
    },
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      searchText: searchText
    }
  };
  return await axios(requestConfig);
};
