import React from 'react';
import {
  UncontrolledAlert,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody
} from 'reactstrap';
import defaultVehicleImage from '../../../assets/images/defaultVehicleImage.png';
import { findErrorMessage, getLocaleTranslator } from '../../../util/i18n/i18nService';
import ModalPinLocked from './ModalPinLocked';
import ModalInputPIN from './ModalInputPIN';
import ModalInputOTP from './ModalInputOTP';
import LandingPage, { findPostGarageLandingPage, ValidDeeplinkLandingPagesPerTSP } from './LandingPage';
import plusIcon from '../../../assets/icons/add.svg';
import '../../reusable_cmp_lib/ThemedModal/ThemedModal.scss';
import { findVehicle, TSP_PROVIDER } from '../providers/SelfEnrollmentProvider';
import appStoreApple from '../../../assets/icons/appStoreApple.svg';
import appStoreGoogle from '../../../assets/icons/appStoreGoogle.svg';
import { hasValidImageUrl } from '../providers/ServiceProxy';
import resource from '../../../config/resource.json';
import { findIn } from '../../../util/utilityMethods';
import { AssociationStatus, getSpinStatus, isTosRequired } from '../providers/GarageProvider';

import { getQueryValue, clearQueryValue, QUERY_CONST } from '../../../providers/queryActionsProvider';
import { setTitle } from '../../../providers/documentTitleProvider';
import { getLocaleSync } from '../../../providers/languageProvider';

class VehicleInfo extends React.Component {
  constructor (props) {
    super(props);

    const { spinStatus = '' } = props;

    this.state = {
      spinStatus,
      isOpenModalPIN: false,
      isOpenModalOTP: false,
      isOpenModalVztChangeServices: false,
      settingMenuVisible: false,
      alert: null,
      landingPage: LandingPage.DEFAULT,
      imageUrl: defaultVehicleImage,
      finalDestinationAfterSpin: null
    };

    this.translator = getLocaleTranslator();
  }

  componentDidMount = async () => {
    if (
      !this.props.enrollmentStatus ||
      !this.props.enrollmentStatus.vehicle ||
      typeof this.props.enrollmentStatus.vehicle.representativeImgURLComplete !== 'string'
    ) {
      return;
    }

    if (await hasValidImageUrl(this.props.enrollmentStatus.vehicleId)) {
      this.setState({ imageUrl: this.props.enrollmentStatus.vehicle.representativeImgURLComplete }, () => {
        this.checkForVehicleIdForAutoSpinAndSetFinalDestinationAfterSpin();
      });
    } else {
      this.checkForVehicleIdForAutoSpinAndSetFinalDestinationAfterSpin();
    }
  };

  checkForVehicleIdForAutoSpinAndSetFinalDestinationAfterSpin = () => {
    // determine if PIN entry should be auto-triggered by a deeplink entry point
    let vehicleIdAutoInitiatSpin = getQueryValue(QUERY_CONST.VEHICLE_ID_KEY);
    let finalDestinationKey = getQueryValue(QUERY_CONST.FINAL_DESTINATION_KEY);
    let tspProvider = findIn('enrollmentStatus.vehicle.tspProvider', this.props) || null;
    // if the specified "finalDestination" is not applicable to that TSP, route to the default
    let finalDestinationAfterSpin =
      (ValidDeeplinkLandingPagesPerTSP[tspProvider] &&
        ValidDeeplinkLandingPagesPerTSP[tspProvider][finalDestinationKey]) ||
      null;

    if (vehicleIdAutoInitiatSpin && this.props.enrollmentStatus.vehicle.vehicleId === vehicleIdAutoInitiatSpin) {
      this.setState(
        {
          finalDestinationAfterSpin
        },
        () => {
          console.log(
            'VehicleInfo >> AUTO_SPIN_INITIATED >> vehicleId: ' +
              vehicleIdAutoInitiatSpin +
              ' & finalDestinationAfterSpin: ' +
              finalDestinationAfterSpin
          );
          clearQueryValue(QUERY_CONST.VEHICLE_ID_KEY);
          clearQueryValue(QUERY_CONST.FINAL_DESTINATION_KEY);
          this.onSelect();
        }
      );
    }
  };

  startProActiveEnrollment = async () => {
    console.log('Starting startProActiveEnrollment for vin ' + this.props.enrollmentStatus.vehicle.vin);
    await findVehicle(this.props.enrollmentStatus.vehicle.vin);

    document.location.href = LandingPage.SELF_ENROLLMENT;
  };

  onSuccessPinAuth = data => {
    if (data && data.exp) {
      Object.assign(data, this.props.enrollmentStatus.vehicle);

      data.garageSize = this.props.garageSize;

      const carNameDesc = findIn('enrollmentStatus.carName.carNameDesc', this.props);
      if (carNameDesc) {
        data.nickName = carNameDesc;
      }

      this.props.actions.setUserVehicleContext(data);

      document.location.href = this.state.landingPage;
    }
  };

  onSuccessResetSPIN = async data => {
    document.location.href = '/';
    //  const { spinStatus } = await getSpinStatus();
    //  this.setState({spinStatus}, this.toggleModalOTP);
  };

  toggleModalPIN = e => {
    e.preventDefault();

    this.setState({ isOpenModalPIN: !this.state.isOpenModalPIN, alert: null });
  };

  toggleModalOTP = async () => {
    let isOpenModalOTP = !this.state.isOpenModalOTP;

    this.setState({
      isOpenModalOTP: isOpenModalOTP,
      isOpenModalPIN: !isOpenModalOTP,
      alert: null
    });
  };

  toggleChangeServices = () => {
    if (this.props.enrollmentStatus.vehicle.tspProvider === TSP_PROVIDER.VZT) {
      this.setState({ isOpenModalVztChangeServices: !this.state.isOpenModalVztChangeServices });

      return;
    }

    this.setState({ landingPage: LandingPage.CHANGE_SERVICES, isOpenModalPIN: true, alert: null });
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  onSelect = e => {
    // to handle inorganic event handling
    if (e && e.preventDefault && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    const enrollmentStatus = this.props.enrollmentStatus || {};

    if (
      isTosRequired(enrollmentStatus, this.props.tosManifest) &&
      enrollmentStatus.associationStatus === AssociationStatus.ASSOCIATION_VERIFIED
    ) {
      const userCountry = getLocaleSync()?.userCountry?.toUpperCase();
      if (userCountry === 'CA') {
        this.props.onCarnetTosVersionDifference();

        return;
      }

      document.location.href = LandingPage.TOS_CARNET + '/' + enrollmentStatus.vehicleId;

      return;
    }

    const tspProvider = findIn('vehicle.tspProvider', enrollmentStatus);
    const modelName = findIn('vehicle.modelName', enrollmentStatus);
    const landingPage = this.state.finalDestinationAfterSpin || findPostGarageLandingPage(tspProvider, modelName);

    this.setState({ landingPage, isOpenModalPIN: true, alert: null }, () => {
      // enter PIN modal displayed, set title for entering PIN
      setTitle('document_titles.garage.enter_pin');
    });
  };

  findPinPrompt = () => {
    /*
    if (this.props.spinStatus === 'LOCKED')
      return (
        <ModalPinLocked
          toggle={this.toggleModalPIN}
          visible={this.state.isOpenModalPIN}
          toggleModalOTP={this.toggleModalOTP}
        />
      );
    */
    const enrollmentStatus = this.props.enrollmentStatus || {};
    const stolenFlag = findIn('vehicle.stolenFlag', enrollmentStatus);
    if (stolenFlag === 'Y') {
      return (
        <Modal
          isOpen={this.state.isOpenModalPIN}
          toggle={this.toggleModalPIN}
          centered={true}
          className='carnet-sg-modal'
        >
          <ModalBody>
            <h2 className='font-weight-bold'>{this.translator.t('garage.service_disabled')}</h2>
            <span>{this.translator.t('garage.stolen_vehicle_service_disabled_desc')}</span>
            <br />
            <br />
            <button className='modal-primary-btn d-block px-5 mx-auto' onClick={this.toggleModalPIN}>
              {this.translator.t('button.ok')}
            </button>
          </ModalBody>
        </Modal>
      );
    }
    /*
    const tspProvider = findIn('vehicle.tspProvider', enrollmentStatus);
    if (tspProvider === TSP_PROVIDER.WCT && this.state.landingPage !== LandingPage.CHANGE_SERVICES) {
      return (
        <Modal
          isOpen={this.state.isOpenModalPIN}
          toggle={this.toggleModalPIN}
          centered={true}
          className='carnet-sg-modal'
        >
          <ModalBody>
            <h2 className='font-weight-bold'>{this.translator.t('garage.experience_ev_features')}</h2>
            <span>{this.translator.t('garage.experience_ev_features_desc')}</span>
            <br />
            <div className='row text-center'>
              <div className='col-sm-6 mt-3'>
                <a href={resource.carnet_mobile.app_store_url_ios} target='blank'>
                  <img src={appStoreApple} alt='AppleStore' />
                </a>
              </div>
              <div className='col-sm-6 mt-3'>
                <a href={resource.carnet_mobile.app_store_url_android} target='blank'>
                  <img src={appStoreGoogle} alt='GoogleStore' />
                </a>
              </div>
            </div>
            <br />
            <button className='modal-primary-btn d-block px-5 mx-auto' onClick={this.toggleModalPIN}>
              {this.translator.t('button.okay')}
            </button>
          </ModalBody>
        </Modal>
      );
    }
*/
    return (
      <ModalInputPIN
        spinStatus={this.state.spinStatus}
        toggle={this.toggleModalPIN}
        visible={this.state.isOpenModalPIN}
        translator={this.translator}
        onSuccess={this.onSuccessPinAuth}
        toggleModalOTP={this.toggleModalOTP}
        enrollmentStatus={this.props.enrollmentStatus}
      />
    );
  };

  render () {
    const isDefaultImage = this.state.imageUrl === defaultVehicleImage;
    let backgrounImageStyle = {
      backgroundImage: 'url(' + this.state.imageUrl + ')'
    };

    if (isDefaultImage) {
      backgrounImageStyle['backgroundSize'] = '50%';
      // bump default background image up 12px to make more room for text
      backgrounImageStyle['backgroundPositionY'] = '-12px';
    }

    let vehicleName = findIn('enrollmentStatus.carName.carNameDesc', this.props);

    if (!vehicleName) {
      if (String(this.props.locale).toLowerCase() === 'fr-ca') {
        vehicleName = `${this.props.enrollmentStatus.vehicle.modelName} ${this.props.enrollmentStatus.vehicle.modelYear}`;
      } else {
        vehicleName = `${this.props.enrollmentStatus.vehicle.modelYear} ${this.props.enrollmentStatus.vehicle.modelName}`;
      }
    }

    let vin = findIn('enrollmentStatus.vehicle.vin', this.props) || '';
    if (this.props.enrollmentStatus.associationStatus === AssociationStatus.ASSOCIATION_VERIFIED)
      return (
        <div className='vehicleInfo' style={backgrounImageStyle} aria-labelledby={`lblVehicle-${vin}`}>
          <ButtonDropdown
            isOpen={this.state.settingMenuVisible}
            toggle={() => this.setState({ settingMenuVisible: !this.state.settingMenuVisible })}
            className='vehicleOptions'
            direction='left'
          >
            <DropdownToggle color='' className='cwpMenu' title={this.translator.t('garage.vehicle_options')}>
              <span className='sr-only'>{this.translator.t('garage.vehicle_options_toggle_dropdown')}</span>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem className='text-danger' onClick={this.toggleChangeServices}>
                {this.translator.t('garage.change_services')}
              </DropdownItem>
              <DropdownItem disabled>{this.translator.t('garage.manage_permissions')}</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <span
            id={`lblVehicle-${vin}`}
            onClick={this.onSelect}
            tabIndex={0}
            role='button'
            className='vehicleName cwpFocusVisible'
          >
            {vehicleName}
          </span>
          <span
            onClick={this.onSelect}
            tabIndex={0}
            role='button'
            className='arrowRight float-right cwpFocusVisible'
            title={this.translator.t('garage.next')}
          >
            <span className='sr-only'>
              {this.translator.t('garage.select_vehicle')} {vehicleName}
            </span>
          </span>
          <br />
          <span className='h6 font-weight-bold'>
            {this.translator.t('pin_prompt.vin')}: {this.props.enrollmentStatus.vehicle.vin}
          </span>
          <br />
          {this.findPinPrompt()}

          {this.props.enrollmentStatus.vehicle.tspProvider !== TSP_PROVIDER.VZT && (
            <ModalInputOTP
              toggle={this.toggleModalOTP}
              visible={this.state.isOpenModalOTP}
              translator={this.translator}
              onSuccess={this.onSuccessResetSPIN}
            />
          )}

          {this.props.enrollmentStatus.vehicle.tspProvider === TSP_PROVIDER.VZT && (
            <Modal
              isOpen={this.state.isOpenModalOTP}
              toggle={this.toggleModalOTP}
              centered={true}
              className='carnet-sg-modal'
            >
              <ModalBody>
                <h2 className='font-weight-bold text-center'>{this.translator.t('garage.reset_pin')}</h2>
                <span className='d-block text-center'>{this.translator.t('garage.reset_pin_vzt_info')}</span>
                <br />
                <br />
                <button className='modal-primary-btn w-100 mx-auto d-block' onClick={this.toggleModalOTP}>
                  {this.translator.t('garage.btn_got_it')}
                </button>
              </ModalBody>
            </Modal>
          )}

          <Modal
            isOpen={this.state.isOpenModalVztChangeServices}
            toggle={this.toggleChangeServices}
            centered={true}
            className='carnet-sg-modal'
          >
            <ModalBody>
              <h2 className='font-weight-bold'>{this.translator.t('garage.change_services')}</h2>
              <span>{this.translator.t('garage.change_services_desc_vzt')}</span>
              <br />
              <br />
              <button className='modal-primary-btn w-100 mx-auto d-block' onClick={this.toggleChangeServices}>
                {this.translator.t('garage.btn_got_it')}
              </button>
            </ModalBody>
          </Modal>
        </div>
      );

    return (
      <div className='vehicleInfo' style={backgrounImageStyle}>
        <ButtonDropdown
          isOpen={this.state.settingMenuVisible}
          toggle={() => this.setState({ settingMenuVisible: !this.state.settingMenuVisible })}
          className='vehicleOptions'
          direction='left'
        >
          <DropdownToggle color='' className='cwpMenuDisabled'></DropdownToggle>
          <DropdownMenu>
            <DropdownItem disabled>{this.translator.t('garage.add_vehicle')}</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        <span className='vehicleName'>{vehicleName}</span>
        <br />
        <span>
          {this.translator.t('pin_prompt.vin')}: {this.props.enrollmentStatus.vehicle.vin}
        </span>
        <br />
        <button className='cwpSecondary' onClick={this.startProActiveEnrollment}>
          <img src={plusIcon} alt='clck to add vehicle' width='13' className='mb-1 mr-1' />
          {this.translator.t('garage.add_vehicle')}
        </button>
      </div>
    );
  }
}

export default VehicleInfo;
