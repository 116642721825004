import { createLogic } from 'redux-logic';
import axios from 'axios';
import {
  GET_USER_SECURITY_QUESTIONS,
  GET_USER_SECURITY_QUESTIONS_SUCCESS,
  GET_USER_SECURITY_QUESTIONS_FAILURE
} from '../../actions/types';

let dataProvider = axios;

let requestConfig = {
  method: 'GET',
  url: `userquestions`,
  headers: {
    accept: 'application/json'
  }
};

const getUserSecurityQuestionsLogic = createLogic({
  type: GET_USER_SECURITY_QUESTIONS,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: GET_USER_SECURITY_QUESTIONS_SUCCESS,
    failType: GET_USER_SECURITY_QUESTIONS_FAILURE
  },

  process ({ action }) {
    console.log('getUserSecurityQuestionsLogic is processing an action >>>');
    console.log('type: ' + action.type);
    console.log('payload: ' + JSON.stringify(action.payload));

    return dataProvider(requestConfig)
      .then(response => {
        console.log('got response for getUserSecurityQuestionsLogic GET request >>> ');
        console.log(JSON.stringify(response.data, null, 1));
        return response;
      })
      .catch(error => {
        console.log('Error while processing getUserSecurityQuestionsLogic');
        console.log(JSON.stringify(error, null, 1));
      });
  }
});

export default [getUserSecurityQuestionsLogic];
