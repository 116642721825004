import {
  GET_CUSTOMER_ACCOUNT_DETAILS,
  GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS,
  GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE,
  UPDATE_CUSTOMER_DETAILS_SUCCESS
} from '../../actions/types';

const initalState = {
  customerData: null,
  dataLoading: false
};

const customerAccountDetailsReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_CUSTOMER_ACCOUNT_DETAILS:
      newState = { ...initalState };
      newState['dataLoading'] = true;
      return newState;

    case GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS:
      const accountDetails = responseData && responseData.data && responseData.data.data;
      newState['customerData'] = accountDetails;
      newState['dataLoading'] = false;
      console.log(JSON.stringify(newState));
      return newState;

    case UPDATE_CUSTOMER_DETAILS_SUCCESS:
      const updatedAccountDetails = responseData && responseData.data;
      newState['customerData']['customer'] = updatedAccountDetails;
      return newState;

    case GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE:
      newState['customerData'] = null;
      newState['dataLoading'] = false;
      return newState;
    default:
      return newState;
  }
};

export default customerAccountDetailsReducer;
