import {
  GET_USER_SECURITY_QUESTIONS,
  GET_USER_SECURITY_QUESTIONS_SUCCESS,
  GET_USER_SECURITY_QUESTIONS_FAILURE
} from '../../actions/types';

const initalState = {
  customerData: null,
  dataLoading: false
};

const userSecurityQuestionsReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};
  switch (actionType) {
    case GET_USER_SECURITY_QUESTIONS:
      newState = { ...initalState };
      return newState;
    case GET_USER_SECURITY_QUESTIONS_SUCCESS:
      const rcvdData = responseData && responseData.data && responseData.data.data;
      newState['data'] = rcvdData || null;
      newState['dataLoading'] = true;
      return newState;
    case GET_USER_SECURITY_QUESTIONS_FAILURE:
      newState['data'] = null;
      newState['dataLoading'] = false;
      return newState;
    default:
      return newState;
  }
};

export default userSecurityQuestionsReducer;
