import React from 'react';
import PropTypes from 'prop-types';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import { UncontrolledAlert } from 'reactstrap';
import { submitTosCarnetUBI, getUBIInsuranceProviders } from '../providers/SelfEnrollmentProvider';

class TosUBI_Optional extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      alert: null,
      processing: false,
      tosVersion: props.existingTOS.tosVersion,
      tosStatus: props.existingTOS.tosStatus,
      insuranceProviders: [],
      currentlyInsured: null,
      currentlyInsuredWith: null,
      multipleVehiclesInsured: null
    };

    this.translator = getLocaleTranslator();
  }

  static propTypes = {
    existingTOS: PropTypes.object.isRequired,
    afterSubmit: PropTypes.func
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.setState({ processing: true });
    try {
      this.setState({ processing: false });
      const response = await getUBIInsuranceProviders();

      if (response && Array.isArray(response.insuranceProviders)) {
        this.setState({ insuranceProviders: response.insuranceProviders, processing: false });
      } else {
        this.setState({ processing: false });
      }
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  submitTos = async () => {
    this.setState({ processing: true, alert: null });

    try {
      const response = await submitTosCarnetUBI(
        this.state.tosVersion,
        this.state.tosStatus,
        this.state.currentlyInsured,
        this.state.currentlyInsuredWith,
        this.state.multipleVehiclesInsured
      );

      this.setState({ processing: false });

      this.props.afterSubmit(this.state.tosStatus, response);
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  render () {
    return (
      <>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-10 text-left mx-auto mb-5'>
            <h1 className='text-left float-left mr-3'>
              {this.translator.t('tos_ubi.get_your_driveview')}
              <sup>{this.translator.t('tos_ubi.tm')}</sup> {this.translator.t('tos_ubi.score')}
            </h1>
            <h3 className='font-weight-normal'>{this.translator.t('tos_ubi.daily_weekly_monthly')}</h3>
            {this.getAlerts()}
            <br />
            <div className='row m-0 p-0'>
              <div className='col-md-4 px-0'>
                <div className='ubiPhoneScreen'></div>
              </div>
              <div className='col-md-8'>
                <h5 className='mt-5'>{this.translator.t('tos_ubi.learn_about_your_driving_behavior')}</h5>
                <span className='small'>{this.translator.t('tos_ubi.learn_about_your_driving_behavior_desc')}</span>
                <div className='row mx-0 my-md-3'>
                  <div className='col-md-6 p-0'>
                    <h6 className='leftIcon iconBreak'>{this.translator.t('tos_ubi.hard_braking')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.hard_braking_desc')}</spam>
                    <br />
                    <h6 className='leftIcon iconSpeed'>{this.translator.t('tos_ubi.high_speed_driving')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.high_speed_driving_desc')}</spam>
                  </div>
                  <div className='col-md-6 p-0'>
                    <h6 className='leftIcon iconNighttime'>{this.translator.t('tos_ubi.nighttime_driving')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.nighttime_driving_desc')}</spam>
                    <br />
                    <h6 className='leftIcon iconIdle'>{this.translator.t('tos_ubi.idle_time')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.idle_time_desc')}</spam>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>
        <div className='row ubiHilights m-0'>
          <div className='col-md-1'></div>
          <div className='col-md-10 text-left mx-auto my-5'>
            <div className='row m-0 p-0'>
              <div className='col-md-4 px-0'></div>
              <div className='col-md-8'>
                <div className='col-md-8 m-0 p-0'>
                  <h2 className='font-weight-normal my-0'>{this.translator.t('tos_ubi.get_insurance_messages')}</h2>
                  <span className='small'>{this.translator.t('tos_ubi.get_insurance_messages_desc')}</span>
                  <br />
                  <h2 className='font-weight-normal mt-5'>
                    {this.translator.t('tos_ubi.keep_your_current_insurance')}
                  </h2>
                  <span className='small'>{this.translator.t('tos_ubi.keep_your_current_insurance_desc')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>
        <div className='col-md-8 text-left mx-auto my-5'>
          <span className='small'>{this.translator.t('tos_ubi.provide_current_insurer_desc')}</span>
          <br />
          <br />
          <h5>{this.translator.t('tos_ubi.are_you_currently_insured')}</h5>
          <span
            className={this.state.currentlyInsured === true ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={e => this.setState({ currentlyInsured: true })}
          >
            {this.translator.t('tos_ubi.yes_i_am_currently_insured')}
          </span>
          <br />
          <span
            className={this.state.currentlyInsured === false ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={e => this.setState({ currentlyInsured: false })}
          >
            {this.translator.t('tos_ubi.no_i_am_not_insured')}
          </span>
          <br />
          <input
            type='text'
            list='insuranceProviders'
            className='cwp mb-4 mt-2 ml-4'
            placeholder={this.translator.t('tos_ubi.current_insurer')}
            onChange={e => this.setState({ currentlyInsuredWith: e.currentTarget.value })}
          ></input>
          <datalist id='insuranceProviders'>
            {this.state.insuranceProviders.map(provider => (
              <option value={provider.name}></option>
            ))}
          </datalist>
          <h5>{this.translator.t('tos_ubi.do_you_have_multiple_vehicle_insured')}</h5>
          <span
            className={this.state.multipleVehiclesInsured === true ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={e => this.setState({ multipleVehiclesInsured: true })}
          >
            {this.translator.t('tos_ubi.yes')}
          </span>
          <br />
          <span
            className={this.state.multipleVehiclesInsured === false ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={e => this.setState({ multipleVehiclesInsured: false })}
          >
            {this.translator.t('tos_ubi.no')}
          </span>
        </div>

        <div className='tos-cont-btn-wrapper'>
          <button
            className='cwp mb-5'
            onClick={this.submitTos}
            disabled={this.state.processing || !this.state.tosStatus}
          >
            {this.translator.t('tos_ubi.continue')}
          </button>
        </div>
      </>
    );
  }
}

export default TosUBI_Optional;
