import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import CreateSPIN from './CreateSPIN';
import VehicleInfo from './VehicleInfo';
import Spinner from '../Spinner';
import {
  getSpinStatus,
  getEnrollmentStatus,
  ProfileStatus,
  findPreGaragePendingManifest,
  findApplicableManifestForCarnetTOS
} from '../providers/GarageProvider';
import LandingPage from './LandingPage';
import { i18nCustomerLocale, getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import './VehicleInfo.scss';
import { UncontrolledAlert } from 'reactstrap';
import { getLocaleSync } from '../../../providers/languageProvider';
import { setTitle } from '../../../providers/documentTitleProvider';
import { getTosManifest } from '../../../providers/tosProvider';
import YourProfileLoader from './YourProfileLoader';
import TosAndPrivacyUpdates from './TosAndPrivacyUpdates';

class Garage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      currentSpinStatus: null,
      customer: null,
      profileStatus: null,
      spinStatus: null,
      vehicleEnrollmentStatus: [],
      spinnerMessage: null,
      alert: null,
      verizon3gSunsetEnabled: null,
      tosManifest: null,
      pendingTosManifest: null
    };

    console.log('inside Garage component constructor, the global render locale is: ' + i18nCustomerLocale);

    this.translator = getLocaleTranslator();
    this.userCountry = '';
  }

  componentDidMount () {
    this.refresh();
    let locale = getLocaleSync();
    this.userCountry = locale ? locale.userCountry.toUpperCase() : 'US';
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.state.vehicleEnrollmentStatus.length) {
      setTitle('document_titles.garage.title_empty');
    } else {
      setTitle('document_titles.garage.title');
    }

    if (this.props.locale !== prevProps.locale) {
      this.reloadTosManifest();
    }
  }

  reloadTosManifest = async () => {
    let { tosManifest, pendingTosManifest } = this.state;

    try {
      tosManifest = await getTosManifest(this.props.locale);

      if (Array.isArray(tosManifest?.manifest) && this.state.pendingTosManifest) {
        pendingTosManifest = tosManifest.manifest.find(m => m.id === this.state.pendingTosManifest?.id);
      }

      this.setState({ tosManifest, pendingTosManifest });
    } catch (error) {
      this.setState({ spinnerMessage: null, alert: findErrorMessage(error, this.translator) });
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite'>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  refresh = async () => {
    this.setState({
      spinnerMessage: this.translator.t('garage.getting_your_vehicles')
    });

    try {
      const enrollmentStatus = await getEnrollmentStatus();

      this.setState({
        spinnerMessage: this.translator.t('garage.checking_spin_status')
      });
      const currentSpinStatus = await getSpinStatus();

      this.setState({ spinnerMessage: null });

      if (currentSpinStatus && enrollmentStatus) {
        this.setState({
          ...enrollmentStatus,
          currentSpinStatus: currentSpinStatus
        });
      }

      let tosManifest = this.state.tosManifest;
      if (!tosManifest) {
        const locale = getLocaleSync();

        tosManifest = await getTosManifest(locale);
        this.setState({ tosManifest });
      }

      this.initPreGarageTOSManifestUpdates(tosManifest);
    } catch (error) {
      this.setState({ spinnerMessage: null, alert: findErrorMessage(error, this.translator) });
    }
  };

  initPreGarageTOSManifestUpdates = async tosManifest => {
    if (this.userCountry !== 'CA') {
      return;
    }

    const pendingTosManifest = await findPreGaragePendingManifest(tosManifest);

    this.setState({ pendingTosManifest });
  };

  initPostGarageTOSManifestUpdates = enrollmentStatus => {
    const manifest = findApplicableManifestForCarnetTOS(enrollmentStatus, this.state.tosManifest);
    manifest.vehicleId = enrollmentStatus.vehicleId;

    this.setState({ pendingTosManifest: manifest });
  };

  render () {
    if (this.state.spinnerMessage) {
      return (
        <main role='main' id='main-content'>
          <Spinner message={this.state.spinnerMessage}></Spinner>
        </main>
      );
    }

    if (!this.state.profileStatus || !this.state.currentSpinStatus) {
      return (
        <>
          <main role='main'>
            {this.getAlerts()}
            <h2 aria-live='polite'>{this.translator.t('garage.service_unavailable')}</h2>
          </main>
        </>
      );
    } else if (this.state.profileStatus !== ProfileStatus.PROFILE_AVAILABLE) {
      return (
        <YourProfileLoader
          onSuccess={this.refresh}
          profileStatus={this.state.profileStatus}
          locale={getLocaleSync()?.locale}
          tosManifest={this.state.tosManifest}
        />
      );
    } else if (this.state.currentSpinStatus.spinStatus === 'NONE') {
      return (
        <main role='main'>
          <CreateSPIN {...this.state} refreshGarage={this.refresh}></CreateSPIN>
        </main>
      );
    } else if (this.state.pendingTosManifest) {
      return (
        <main role='main' className='container'>
          <TosAndPrivacyUpdates
            translator={this.translator}
            manifest={this.state.pendingTosManifest}
            onSuccess={() => this.setState({ pendingTosManifest: null }, this.refresh)}
          ></TosAndPrivacyUpdates>
        </main>
      );
    }

    if (!this.state.profileStatus) return <img src={Spinner} alt='loading'></img>;

    return (
      <main role='main' className='garageVehicles' id='main-content'>
        {this.userCountry === 'US' && this.state.verizon3gSunsetEnabled && (
          <div className='verizon3gSunset'>
            <div className='verizon3gSunsetMessage'>
              {this.translator.t('garage.3g_turndown_message1')}
              <a
                href={this.translator.t('garage.3g_turndown_message2')}
                target='_blank'
                rel='noopener noreferrer'
                className='carnetLink'
              >
                {this.translator.t('garage.3g_turndown_message4')}
              </a>
              {this.translator.t('garage.3g_turndown_message3')}
            </div>
          </div>
        )}
        {this.getAlerts()}
        <h1 className='font-weight-normal' aria-live='polite'>
          {this.translator.t('garage.your')} <strong>{this.translator.t('garage.garage')}</strong>
        </h1>
        <br />
        <div className='garageVehicles vehicleWrapper'>
          <span className='pageSubTitle'>{this.translator.t('garage.subtitle')}</span>
          <br />
          <br />
          {this.state.vehicleEnrollmentStatus.map((enrollmentStatus, index) => {
            return (
              <VehicleInfo
                key={`vehicleInfo${index}`}
                enrollmentStatus={enrollmentStatus}
                {...this.state.currentSpinStatus}
                actions={this.props.actions}
                garageSize={this.state.vehicleEnrollmentStatus.length}
                locale={this.props.locale}
                tosManifest={this.state.tosManifest}
                onCarnetTosVersionDifference={() => this.initPostGarageTOSManifestUpdates(enrollmentStatus)}
              />
            );
          })}
        </div>
        <br />
        <br />
        <h2>{this.translator.t('garage.dont_see_your_vehicle')}</h2>
        <br />
        <button type='button' className='cwp' onClick={() => (window.location.href = LandingPage.SELF_ENROLLMENT)}>
          {this.translator.t('garage.add_a_vehicle')}
        </button>
        <br />
        <br />
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setUserVehicleContext: actions.setUserVehicleContext
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { userVehicleContext } = state;

  return {
    ...userVehicleContext
  };
};

export { Garage };
export default connect(mapStateToProps, mapDispatchToProps)(Garage);
