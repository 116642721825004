import {
  GET_CARNET_CUSTOMER_STATIC_DATA,
  GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS,
  GET_CARNET_CUSTOMER_STATIC_DATA_FAILURE
} from '../../actions/types';

const initalState = {
  states: null,
  countries: null,
  securityQuestions: null
};

const carnetCustomerStaticDataReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';

  switch (actionType) {
    case GET_CARNET_CUSTOMER_STATIC_DATA:
      newState = { ...initalState };
      return newState;

    case GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS:
      console.log('carnetCustomerStaticDataReducer >>> GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS');
      console.log('payload:: ', action.payload);
      let responseData = action.payload || {};
      console.log('Inside ur focus reducer, the responseData obj looks like: ' + JSON.stringify(responseData));
      newState.states = (responseData[0] && responseData[0].states) || null;
      newState.countries = (responseData[1] && responseData[1].countries) || null;
      newState.securityQuestions = (responseData[2] && responseData[2].securityQuestions) || null;
      return newState;
    case GET_CARNET_CUSTOMER_STATIC_DATA_FAILURE:
      newState['states'] = null;
      newState['countries'] = null;
      newState['securityQuestions'] = null;
      return newState;
    default:
      return newState;
  }
};

export default carnetCustomerStaticDataReducer;
