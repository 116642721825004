import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Row, Col, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { triggerPasswordResetEmail } from '../providers/AccountAndSecurityProvider';
import openEye from '../../../assets/icons/open-eye-icon.svg';
import closedEye from '../../../assets/icons/crossed-eye-icon.svg';
import '../../reusable_cmp_lib/ThemedModal/ThemedModal.scss';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';

/*
 *  RELEVANT INCOMING PROPS: this.props.secqs = {array of all the security qs} , this.props.usersQuestions : array of 3 user-chosen security Q's and responses
 *   this.props.usersQuestions used in the method: getUserQuestionByIndex() method
 */
class LoginAndSecurity extends React.Component {
  constructor (props) {
    super(props);
    this.idMappedSecQs = [];
    this.state = {
      engagePasswordReset: false,
      enrollmentData: {},
      qAnswerMasking: { 0: true, 1: true }
    };
    if (this.props.secqs && this.props.secqs.length > 0) {
      this.props.secqs.forEach(question => {
        let qId = question.code;
        this.idMappedSecQs[qId] = question;
      });
    } //if()
  } //constructor

  editField (reference) {
    console.log('editField() invoked for ref: ' + reference);
    this.props.editingCallback(reference);
  }

  confirmedPasswordReset = () => {
    let resetStatus = triggerPasswordResetEmail(); //await built into the internals

    if (!resetStatus || resetStatus.statusCode < 200 || resetStatus.statusCode > 204) {
      console.error('ResetPassword operation failed through the service. See server logs.');
    }
    this.togglePasswordResetModal(false);
  };

  bulletText (clearText) {
    if (!clearText) {
      return '';
    }
    return clearText.replace(/./g, '\u2022');
  }

  getUserQuestionByIndex (index) {
    let indexQuestion = this.props.usersQuestions[index];
    const indexQuestionId = indexQuestion.questionId;
    const indexQuestionAnswer = indexQuestion.questionResponse;
    let question = this.idMappedSecQs[indexQuestionId];
    return { q: question, response: indexQuestionAnswer };
  }

  togglePasswordResetModal = show => {
    this.setState({
      engagePasswordReset: show
    });
  };

  toggleSecAnswerVisibility = index => {
    let qAnswerMasking = { ...this.state.qAnswerMasking };
    qAnswerMasking[index] = !qAnswerMasking[index];
    this.setState({ qAnswerMasking });
  };

  componentDidMount () {
    if (this.props.focus) {
      try {
        document.getElementById('firstActiveFocus').focus();
      } catch (err) {
        console.log('element not found for autofocus');
      }
    }
    setTitle('document_titles.account_management.login_and_security');
  }

  componentDidUpdate (prevProps) {
    let pre = JSON.stringify(prevProps.secqs);
    let cur = JSON.stringify(this.props.secqs);
    if (pre !== cur) {
      if (this.props.secqs && this.props.secqs.length > 0) {
        this.props.secqs.forEach(question => {
          let qId = question.code;
          this.idMappedSecQs[qId] = question;
        });
      }
      this.forceUpdate();
    }
  }

  render () {
    this.translator = getLocaleTranslator();

    return (
      <div className='acctmgmt-contentpane'>
        <div className='contentpane-title'>
          <h2 id='firstActiveFocus' tabIndex={-1} className='h6'>
            {this.translator.t('acctmgmt_lns_heading')}
          </h2>
        </div>
        <div>
          <Row>
            <Col>
              <span className='carnet-theme-field-label'>
                {/*PIN*/}
                {this.translator.t('acctmgmt_label_lns_field_pin')}
              </span>
            </Col>
            <Col md='6'>
              <span className='carnet-theme-field-value'>&bull;&bull;&bull;&bull;</span>
            </Col>
            <Col>
              <button
                className='btn btn-link carnetLinkLight'
                type='button'
                onClick={() => this.editField('lns-field-pin')}
              >
                {this.translator.t('acctmgmt_pi_update_link_label')}
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className='carnet-theme-field-label'>
                {/*Password*/}
                {this.translator.t('acctmgmt_label_lns_field_password')}
              </span>

              {/* Conditional Display the Pass Reset Modal */}
              <Modal
                isOpen={this.state.engagePasswordReset}
                className='carnet-sg-modal'
                labelledBy='headerIdResetPassword'
                aria-live={'polite'}
                returnFocusAfterClose={true}
                onOpened={() => {
                  try {
                    document.getElementById('cancelChangePasswordModalBtn').focus();
                  } catch (err) {
                    console.log('unable to get focus el');
                  }
                }}
              >
                <div class='modal-header'>
                  <h2 id='headerIdResetPassword' className='h5 font-weight-bold modal-title'>
                    {this.translator.t('acctmgmt_label_lns_resetpass')}
                  </h2>
                </div>
                <ModalBody>{this.translator.t('acctmgmt_lns_resetpass_instrux')}</ModalBody>
                <ModalFooter>
                  <Button
                    id={'cancelChangePasswordModalBtn'}
                    className='modal-primary-btn cwpFocusVisible'
                    color='modal_background_color'
                    onClick={() => {
                      this.togglePasswordResetModal(false);
                    }}
                  >
                    {this.translator.t('cancel_btn_camel')}
                  </Button>
                  <Button
                    className='modal-secondary-btn cwpFocusVisible'
                    color='modal-text-color'
                    onClick={this.confirmedPasswordReset}
                  >
                    {this.translator.t('send_email_label')}
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
            <Col md='6'>
              <span className='carnet-theme-field-value'>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
            </Col>
            <Col>
              <button
                className='btn btn-link carnetLinkLight'
                type='button'
                onClick={() => this.togglePasswordResetModal(true)}
              >
                {this.translator.t('acctmgmt_pi_update_link_label')}
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className='carnet-theme-field-label'>
                {/*Challenge Question*/}
                {this.translator.t('acctmgmt_label_lns_field_challenge')}
              </span>
            </Col>
            <Col md='6'>
              <div className='carnet-theme-field-value'>
                <ul>
                  {/* iterate users questions */}

                  {(this.props.usersQuestions || []).map((question, index) => {
                    const questionDetail = this.getUserQuestionByIndex(index);
                    const indexQuestion = questionDetail.q || {};
                    const indexAnswer = questionDetail.response;
                    let maskedAnswer = this.bulletText(indexAnswer);
                    return (
                      <li id={indexQuestion.code}>
                        <div className='challenge-question-preview'>{indexQuestion.value}</div>
                        <div className='challenge-question-preview marginBottom20'>
                          <span>{this.state.qAnswerMasking[index] ? maskedAnswer : indexAnswer}&nbsp;&nbsp;</span>
                          <img
                            src={this.state.qAnswerMasking[index] ? closedEye : openEye}
                            alt='toggle visibility of the entry'
                            height='18'
                            width='18'
                            className='mb-1 mr-1 inline right'
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.toggleSecAnswerVisibility(index);
                            }}
                          />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
            <Col>
              <button
                className='btn btn-link carnetLinkLight'
                type='button'
                onClick={() => this.editField('lns-field-secqs')}
              >
                {this.translator.t('acctmgmt_pi_update_link_label')}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default LoginAndSecurity;
