import React from 'react';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import { UncontrolledAlert, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import defaultVehicleImage from '../../../assets/images/defaultVehicleImage.png';
import PropTypes from 'prop-types';

import {
  findApplicableTos,
  TOS_TYPE,
  TSP_PROVIDER,
  submitTosCarnet,
  submitTosCarnetVZT,
  TOS_STATUS
} from '../providers/SelfEnrollmentProvider';

class TosCarnet extends React.Component {
  state = {
    alert: null,
    processing: false,
    tosStatus: null,
    isOpenTosContentModal: false,
    tosContent: null,
    tosVersion: null,
    tspAccountNum: null,
    tspPIN: null,
    pinVisible: false,
    showDeclineConfirmation: false
  };

  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    this.fetchTosContent();
  };

  componentWillReceiveProps (props) {
    if (props.locale !== this.props.locale) {
      this.fetchTosContent();
    }
  }

  fetchTosContent = async () => {
    console.log('TSP Provider in CarNet TOS is ' + this.props.tspProvider);
    this.setState({ processing: true });
    try {
      const { tosVersion, tosContent } = await findApplicableTos(TOS_TYPE.CARNET);

      this.setState({ tosVersion, tosContent, processing: false });
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  parseTosContent = () => {
    const parser = new HtmlToReactParser();
    return parser.parse(this.state.tosContent);
  };

  // helper function to run on rendered html
  // need to hijack <anchor></anchor> tags because of react-router
  parseAndReplaceAnchors = () => {
    setTimeout(() => {
      let el = document.getElementById('tosBodyWrapper');
      let anchors = (el && el.querySelectorAll('a')) || [];
      anchors.forEach(element => {
        let href = element.getAttribute('href');
        let isHash = href && String(href).includes('#');
        let partsArr = isHash && String(href).split('#');
        let divId = null;
        if (Array.isArray(partsArr) && partsArr[0] === '' && partsArr[1]) {
          divId = partsArr[1];
        }
        let div = divId ? document.getElementById(divId) : null;
        if (divId && div) {
          element.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            div.scrollIntoView();
          });
          if (isHash) {
            element.setAttribute('href', '#');
          }
        }
      });
    }, 250);
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  setTosStatus = tosStatus => {
    this.setState({ tosStatus });
  };

  checkAcceptanceThanSubmit = () => {
    if (this.state.tosStatus === TOS_STATUS.ACCEPT) {
      return this.submitTos();
    }
    this.setState({ showDeclineConfirmation: true });
  };

  confirmDeclinationAndContinue = () => {
    this.setState({ showDeclineConfirmation: false }, this.submitTos);
  };

  cancelDeclinationAndContinue = () => {
    this.setState({ showDeclineConfirmation: false });
  };

  submitTos = async () => {
    this.setState({ processing: true, alert: null });

    let response = null;
    try {
      if (this.props.tspProvider === TSP_PROVIDER.VZT) {
        response = await submitTosCarnetVZT(
          this.state.tosVersion,
          this.state.tosStatus,
          this.state.tspAccountNum,
          this.state.tspPIN
        );
      } else {
        response = await submitTosCarnet(this.state.tosVersion, this.state.tosStatus);
      }

      this.setState({ processing: false });
      this.props.afterSubmit(this.state.tosStatus, response);
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  toggleTosContentModal = () => {
    this.setState({ isOpenTosContentModal: !this.state.isOpenTosContentModal });
  };

  isInvalidVztEnrollmentSubmission = () => {
    return this.props.enrollmentProcedure === 'VZT' && (!this.state.tspAccountNum || !this.state.tspPIN);
  };

  onBackClick = () => window.history.back();

  render () {
    const imgUrl = this.props.imgURL || defaultVehicleImage;
    const vehicleName = this.props.modelYear + ' ' + this.props.modelName;

    return (
      <>
        <div className='enrollmentFullWidthLeft'>
          <span className='cwpBack' role='button' onClick={this.onBackClick}>
            {this.translator.t('button.back')}
          </span>
        </div>
        <h1 className='font-weight-normal'>
          {this.translator.t('tos_carnet.your')} <strong>{this.translator.t('tos_carnet.vehicle')}</strong>
        </h1>
        {this.getAlerts()}

        <div className='text-center'>
          <img src={imgUrl} alt={' '} width='300' />
          <br />
          <span className='cwpCol'>
            <h1 className='font-weight-normal'>{vehicleName}</h1>
            <h6>
              {this.translator.t('tos_carnet.vin')}: {this.props.vin}
            </h6>
            <br />
            {this.props.enrollmentProcedure === 'VZT' ? (
              <span className='d-block'>
                <div className='form-group'>
                  <label htmlFor='tspAccountNum'>{this.translator.t('tos_carnet.account_number')}</label>
                  <br />
                  <input
                    id='tspAccountNum'
                    type='text'
                    className='cwp'
                    maxLength='9'
                    autoComplete={false}
                    pattern='[0-9]{9}'
                    required='true'
                    onChange={e => this.setState({ tspAccountNum: e.currentTarget.value })}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='tspPin'>{this.translator.t('tos_carnet.pin')}</label>
                  <br />
                  <input
                    id='tspPin'
                    type={this.state.pinVisible ? 'text' : 'password'}
                    className='cwp'
                    maxLength='4'
                    autoComplete={false}
                    required={true}
                    pattern='[0-9]{4}'
                    title={this.translator.t('pin_prompt.4_digit_number_only')}
                    onChange={e => this.setState({ tspPIN: e.currentTarget.value })}
                  />
                  <span
                    className={this.state.pinVisible ? 'cwpInputEye cwpInputEyeOpen' : 'cwpInputEye'}
                    onClick={e => this.setState({ pinVisible: !this.state.pinVisible })}
                  />
                </div>
              </span>
            ) : (
              ''
            )}
          </span>
        </div>
        <div className='col-md-8 text-left mx-auto my-5'>
          <h1>{this.translator.t('tos_carnet.important_information')}</h1>
          <ul>
            <li>{this.translator.t('tos_carnet.important_information_1')}</li>
            <li>{this.translator.t('tos_carnet.important_information_2')}</li>
            <li>{this.translator.t('tos_carnet.important_information_3')}</li>
            <li>{this.translator.t('tos_carnet.important_information_4')}</li>
          </ul>
          <hr />
          {this.translator.t('tos_carnet.i_have_read_and_accept_the')}{' '}
          <span className='carnetLink' onClick={this.toggleTosContentModal}>
            {this.translator.t('tos_carnet.carnet_terms_of_services')}
          </span>{' '}
          {this.translator.t('tos_carnet.and')}{' '}
          <a href='https://www.vw.com/privacy/' target='blank' className='carnetLink'>
            {this.translator.t('tos_carnet.privacy_statement')}
          </a>
          .<br />
          <br />
          <span
            className={TOS_STATUS.ACCEPT === this.state.tosStatus ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={() => this.setTosStatus(TOS_STATUS.ACCEPT)}
          >
            {this.translator.t('tos_carnet.accept')}
          </span>
          <br />
          <br />
          <span
            className={TOS_STATUS.DECLINE === this.state.tosStatus ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={() => this.setTosStatus(TOS_STATUS.DECLINE)}
          >
            {this.translator.t('tos_carnet.decline')}
          </span>
        </div>
        <div className='tos-cont-btn-wrapper'>
          <button
            className='cwp mb-5'
            onClick={this.checkAcceptanceThanSubmit}
            disabled={this.state.processing || !this.state.tosStatus || this.isInvalidVztEnrollmentSubmission()}
          >
            {this.translator.t('tos_carnet.continue')}
          </button>
        </div>

        <Modal isOpen={this.state.showDeclineConfirmation} className='carnet-sg-modal tosDeclinationConfirmation'>
          <ModalHeader>{''}</ModalHeader>
          <ModalBody>{this.translator.t('tos_carnet.decline_confirmation')}</ModalBody>
          <ModalFooter>
            <div>
              <Button
                className='modal-primary-btn'
                color='modal_background_color'
                onClick={this.confirmDeclinationAndContinue}
              >
                {this.translator.t('button.ok')}
              </Button>
            </div>
            <div>
              <Button
                className='modal-secondary-btn'
                color='modal_background_color'
                onClick={this.cancelDeclinationAndContinue}
              >
                {this.translator.t('button.cancel')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenTosContentModal}
          toggle={this.toggleTosContentModal}
          centered={true}
          className='modal-tos'
        >
          <ModalBody>
            <span className='cwpExit' onClick={this.toggleTosContentModal} />
            <span id='tosBodyWrapper' className='tos-body'>
              {this.parseTosContent()}
            </span>
            {this.parseAndReplaceAnchors()}
          </ModalBody>
        </Modal>
      </>
    );
  }

  static propTypes = {
    locale: PropTypes.string.isRequired
  };
}

export default TosCarnet;
