import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';
import * as actions from '../../../redux/actions';
import { getLocaleSync } from '../../../providers/languageProvider';
import { getLocaleTranslator, getPreferredLanguage } from '../../../util/i18n/i18nService';
import { isNonEmptyObject, isNullOrEmptyObject, findIn } from '../../../util/utilityMethods';
import { getEnrollmentStatus } from '../providers/AccountAndSecurityProvider';
import { Container, Row, Col } from 'reactstrap';
import Leftnav from './Leftnav';
import carnet_constants from '../../../constants/carnet_constants';
import PersonalInfo from './PersonalInfo';
import VehicleInfo from './VehicleInfo';
import AdditionalDrivers from './AdditionalDrivers';
import ManageAlerts from './ManageAlerts';
import SubscriptionInfo from './SubscriptionInfo';
import LoginAndSecurity from './LoginAndSecurity';
import MarketingConsent from './MarketingConsent';
import MarketingConsentsEditor from './MarketingConsentsEditor';
import PersonalNameEditor from './PersonalNameEditor';
import PINEditor from './PINEditor';
import PersonalAddressEditor from './PersonalAddressEditor';
import PhoneNumberEditor from './PhoneNumberEditor';
import BirthdateEditor from './BirthdateEditor';
import EmergencyContactEditor from './EmergencyContactEditor';
import SecurityQuestionsEditor from './SecurityQuestionsEditor';
import ThirdPartyServices from './ThirdPartyServices';
import ServicesEditor from './ServicesEditor';
import VehicleNicknameEditor from './VehicleNicknameEditor';
import FindADealerNew from './FindADealerNew';
import WifiSettings from './WifiSettings';
import WifiSettingsEditor from './WifiSettingsEditor';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';

/*    this.props.customerData IS the now-working Redux-loaded data of userInfo    */
class AccountManagement extends React.Component {
  constructor (props) {
    super(props);
    this.translator = null;

    this.state = {
      focusMode: 'pi',
      editingField: null,
      editingLabel: '',
      enrollmentData: {},
      liveUserInfo: null,
      usersSecQs: null,
      contact: null,
      currentSaveResult: 0,
      closeToasts: true
    };

    this.setEditedField = this.setEditedField.bind(this);
    this.props.actions.getCarnetCustomerStaticData();
  }

  componentWillUnmount () {
    this.setState({
      closeToasts: true
    });
  }

  componentDidMount = () => {
    this.props.actions.getCarnetCustomerStaticData();
    this.getCustomerAccountAndUserInfo();
    this.props.actions.getUserSecurityQuestions();
    this.loadEnrollmentData();
    this.setState({
      closeToasts: true,
      liveUserInfo: null
    });
  };

  componentDidUpdate (prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.setState({ usersSecQs: null }, () => {
        this.props.actions.getCarnetCustomerStaticData();
        if (this.isAccountManagementMain() || window.location.hash === '#/acct-mgmt/personal') {
          setTitle('document_titles.profile_page');
        }
      });
    }
  }

  getCustomerAccountAndUserInfo = async () => {
    this.props.actions.getCustomerAccountDetails();
  };

  static getDerivedStateFromProps (nextProps, prevState) {
    if (isNonEmptyObject(prevState.liveUserInfo)) {
      return null;
    }
    let outgoingDelta = {};
    if (isNonEmptyObject(nextProps.customerData)) {
      let injectedCustomerData = JSON.parse(JSON.stringify(nextProps.customerData));
      outgoingDelta['liveUserInfo'] = injectedCustomerData;
    }
    return outgoingDelta;
  }

  loadEnrollmentData = async () => {
    let data;
    try {
      data = await getEnrollmentStatus();
      this.setState({
        enrollmentData: data
      });
    } catch (err) {
      data = { error: err };
    }
  };

  saveChangeToContextVehicle = deltaGraft => {
    if (!deltaGraft) {
      return;
    }
    console.log(':::::: AccountManagement rcvd a vehicle Save graft: ' + JSON.stringify(deltaGraft));
    const enrollmentData = this.state.enrollmentData || {};
    let enrollmentProfile = JSON.parse(JSON.stringify(enrollmentData)); //DEEP-dish Cloning
    let vehiclesArr = enrollmentProfile.vehicleEnrollmentStatus && enrollmentProfile.vehicleEnrollmentStatus;
    const ctxVehicle = vehiclesArr[0];

    let updatedContextVehicle = {
      ...ctxVehicle,
      ...deltaGraft
    }; //Object merge

    enrollmentProfile.vehicle = updatedContextVehicle;

    //now invoke it:
    this.props.actions.saveAcctAndVehiclePrefs(enrollmentProfile);
  };

  mergeEditsEmergencyContact = edits => {
    if (isNullOrEmptyObject(edits)) {
      return;
    }
    this.setState({
      contact: edits
    });
  };

  mergeEditsSecurityQs = edits => {
    if (isNullOrEmptyObject(edits)) {
      return;
    }
    this.setState({
      usersSecQs: edits
    });
  };

  mergeEditsUserInfo = (edits, ptr) => {
    if (isNullOrEmptyObject(edits)) {
      return;
    }

    let deepClone = JSON.parse(JSON.stringify(this.state.liveUserInfo));
    let nxUserInfo;
    if (ptr === null) {
      nxUserInfo = {
        ...deepClone,
        ...edits
      };
    } else {
      deepClone[ptr] = edits;
      nxUserInfo = deepClone;
    }

    this.setState({
      liveUserInfo: nxUserInfo
    });
  };

  persistChangeToCustomerDetail = mutatedCustomerProfile => {
    console.log('Root save handler hit. With: ' + JSON.stringify(mutatedCustomerProfile));

    if ('{}' === JSON.stringify(mutatedCustomerProfile)) {
      return;
    }
    this.setState({
      closeToasts: false
    });
    this.props.actions.updateCustomerDetails(mutatedCustomerProfile);
  };

  /* updater for any field */
  recordFieldEdit = (fieldName, nxVal) => {
    let mutatorObj = {};
    mutatorObj[fieldName] = nxVal;
    this.setState(mutatorObj);
  };

  setEditedField = reference => {
    let i18nLabelUpdate = 'acctmgmt_pi_update_link_label';
    let i18nLabelEdit = 'acctmgmt_pi_edit_link_label';
    let editingVerb = reference === 'pi_username' ? i18nLabelUpdate : i18nLabelEdit;
    if (reference === carnet_constants.FIND_DEALER) {
      editingVerb = 'acctmgmt_finddealer_label';
    }
    this.setState({
      editingField: reference,
      editingLabel: editingVerb
    });
  };

  isAccountManagementMain = () => window.location.hash === '#/acct-mgmt';

  setFocusOnBackAfterModalDismissed = () => {
    try {
      document.getElementById('backButton').focus();
    } catch (err) {
      console.log('no backbutton to be found for auto focus');
      try {
        document.getElementById('firstActiveFocus').focus();
      } catch (err) {
        console.log('first active element not found for autofocus');
      }
    }
  };

  render () {
    let details = this.props.accountDetails || {};
    let customerLocale = getPreferredLanguage();
    const customerData = this.state.liveUserInfo || this.props.customerData || {};
    const usersOwnSecurityQuestions =
      this.state.usersSecQs ||
      (this.props.userSecurityQuestions &&
        this.props.userSecurityQuestions.data &&
        this.props.userSecurityQuestions.data.securityQuestions) ||
      [];
    let contextVehicle = (this.props.accountDetails && this.props.accountDetails.vehicle) || {};
    let loadedLocale =
      (this.props.accountDetails &&
        this.props.accountDetails.customer &&
        this.props.accountDetails.customer.localeCode) ||
      carnet_constants.US_LOCALE; //default to U.S. English
    ///default to en-US if it could not be loaded
    let vehicleTsp = (contextVehicle && contextVehicle.tspProvider) || 'UNKNOWN';
    const vehicleTimezone = (this.props.accountDetails && this.props.accountDetails.vehicleTimeZone) || 'NOT AVAILABLE';
    const vpairedDevicesArr = (this.props.accountDetails && this.props.accountDetails.pairedDevices) || [];
    this.translator = getLocaleTranslator();

    let modeSubtitle = this.translator.t(this.state.editingLabel) || ''; //we must defer ACTUAL translation to Here in render, for Language-Selector to work

    let usersCurrentEmail =
      (this.props.accountDetails && this.props.accountDetails.customer && this.props.accountDetails.customer.email) ||
      null;
    let loadedMNO = '';
    let findinResult = findIn('accountDetails.vehicle.ocuSim.defaultMno', this.props);
    if (findinResult !== false) {
      loadedMNO = this.props.accountDetails.vehicle.ocuSim.defaultMno;
    }
    let receivedSaveResult = this.props.saveResult === true || this.props.saveResult === false;
    if (this.state.closeToasts === true) {
      receivedSaveResult = null;
    }
    let toastTitle, toastMsg;
    if (receivedSaveResult) {
      toastTitle = this.props.saveResult
        ? this.translator.t('acctmgmt_saved')
        : this.translator.t('acctmgmt_savefailed');
      toastMsg = this.props.saveResult
        ? this.translator.t('acctmgmt_api_save_ok_msg')
        : this.translator.t('acctmgmt_api_failed_msg');
    }

    return (
      <div className='carnet-acctmgmt-parent cwpContentHeightMin'>
        {receivedSaveResult && (
          <ThemedToast
            title={toastTitle || ''}
            msg={toastMsg || ''}
            display={receivedSaveResult && !this.state.closeToasts}
            returnFocusAfterClose={false}
            onClosed={this.setFocusOnBackAfterModalDismissed}
          />
        )}

        {this.state.editingField === null && (
          <div id='main-content' role='main'>
            <h1 className='pageHeader h3'>{this.translator.t('acctmgmt_page_title')}</h1>
            <Container fluid={true}>
              <Row>
                <Col md='4'>
                  <Leftnav isCanadian={this.props.isCanadianUser || false} tsp={vehicleTsp} />
                </Col>
                <Col md='8'>
                  {/*
                    When just landing on the main view need to have this as the main component,
                    if we set the main route to the nested /acct-mgmt/personal, 
                    it mangles the rest of the routing on account management
                  */}
                  {this.isAccountManagementMain() && (
                    <PersonalInfo
                      focus={true}
                      tsp={vehicleTsp}
                      accountData={customerData}
                      isCanadian={this.props.isCanadianUser || false}
                      accountDetails={details}
                      communicationLanguage={this.props.communicationLanguage}
                      saveHandler={this.persistChangeToCustomerDetail}
                      refreshHandler={this.mergeEditsUserInfo}
                      usersLocale={loadedLocale}
                      parentCmp={this}
                      birthDate={this.props.birthDate}
                      contact={this.state.contact || (details && details.customer && details.customer.emergencyContact)}
                      editingCallback={this.setEditedField}
                      locale={this.props.locale}
                    />
                  )}

                  {!this.isAccountManagementMain() && (
                    <Router>
                      <Switch>
                        <Route path='/acct-mgmt/personal'>
                          <PersonalInfo
                            focus={true}
                            tsp={vehicleTsp}
                            accountData={customerData}
                            isCanadian={this.props.isCanadianUser || false}
                            accountDetails={details}
                            communicationLanguage={this.props.communicationLanguage}
                            saveHandler={this.persistChangeToCustomerDetail}
                            refreshHandler={this.mergeEditsUserInfo}
                            usersLocale={loadedLocale}
                            parentCmp={this}
                            birthDate={this.props.birthDate}
                            contact={
                              this.state.contact || (details && details.customer && details.customer.emergencyContact)
                            }
                            editingCallback={this.setEditedField}
                            locale={this.props.locale}
                          />
                        </Route>

                        <Route path='/acct-mgmt/security'>
                          <LoginAndSecurity
                            focus={true}
                            editingCallback={this.setEditedField}
                            secqs={this.props.securityQuestions}
                            usersQuestions={usersOwnSecurityQuestions}
                            locale={this.props.locale}
                          />
                        </Route>

                        {/* marketing consent */}
                        {this.props.isCanadianUser === true && (
                          <Route path='/acct-mgmt/marketing'>
                            <MarketingConsent editingCallback={this.setEditedField} focus={true} />
                          </Route>
                        )}

                        <Route path='/acct-mgmt/vehicle'>
                          <VehicleInfo
                            focus={true}
                            enrollmentStatus={this.state.enrollmentData}
                            isCanadian={this.props.isCanadianUser || false}
                            tsp={vehicleTsp}
                            vehicleTz={vehicleTimezone}
                            pairedDevices={vpairedDevicesArr}
                            editingCallback={this.setEditedField}
                            accountDetails={this.props.accountDetails}
                            vehicleData={contextVehicle}
                            locale={this.props.locale}
                          />
                        </Route>

                        <Route path='acct-mgmt/subscriptions'>
                          <SubscriptionInfo contextVehicleId={contextVehicle.vehicleId} />
                        </Route>

                        {this.props.isCanadianUser === false && (
                          <Route path='/acct-mgmt/managealerts'>
                            <ManageAlerts
                              focus={true}
                              editsSaveHandler={this.persistChangeToCustomerDetail}
                              accountDetails={this.props.accountDetails}
                              tsp={vehicleTsp}
                            />
                          </Route>
                        )}

                        {vehicleTsp && vehicleTsp !== carnet_constants.TSP_VZT && (
                          <>
                            <Route path='/acct-mgmt/additionaldrivers'>
                              <AdditionalDrivers
                                focus={true}
                                tsp={vehicleTsp}
                                locale={this.props.locale}
                                accountDetails={this.props.accountDetails}
                                refreshSourceDataCallback={this.getCustomerAccountAndUserInfo}
                                setFocusOnBackAfterModalDismissed={this.setFocusOnBackAfterModalDismissed}
                              />
                            </Route>

                            <Route path='/acct-mgmt/wifisettings'>
                              <WifiSettings
                                focus={true}
                                locale={this.props.locale}
                                quotedMno={loadedMNO}
                                tsp={vehicleTsp}
                                editingCallback={this.setEditedField}
                              />
                            </Route>

                            <Route path='/acct-mgmt/thirdparty'>
                              <ThirdPartyServices
                                focus={true}
                                locale={this.props.locale}
                                editingCallback={this.setEditedField}
                                accountDetails={this.props.accountDetails}
                                tsp={vehicleTsp}
                                isCanadianUser={this.props.isCanadianUser}
                              />
                            </Route>
                          </>
                        )}
                      </Switch>
                    </Router>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}

        {/*  Editing Mode */}
        {this.state.editingField !== null && (
          <div className='topOfEditContent' id='main-content' role='main'>
            <div id='exit-editing-link'>
              <span
                id='backButton'
                class='cwpBack'
                role='button'
                onClick={e => {
                  e.preventDefault();
                  this.setEditedField(null);
                }}
                onKeyPress={e => {
                  e.preventDefault();
                  if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                    this.setEditedField(null);
                  }
                }}
                tabIndex={0}
              >
                {this.translator.t('acctmgmt_nav_backtoacctmgmt')}
              </span>
            </div>

            <h1 aria-live='polite'>{modeSubtitle}</h1>

            {/* REMOVE AFTER DEBUGGING */}
            {this.state.editingField === 'pi-name-field' && (
              <PersonalNameEditor
                saveHandler={this.persistChangeToCustomerDetail}
                accountData={customerData}
                refreshCallback={this.mergeEditsUserInfo}
                editingCallback={this.setEditedField}
              />
            )}
            {this.state.editingField === 'pi-field-address' && (
              <PersonalAddressEditor
                saveHandler={this.persistChangeToCustomerDetail}
                isCanadian={this.props.isCanadianUser || false}
                accountData={customerData}
                address={customerData.address}
                countries={this.props.countries}
                states={this.props.states}
                editingCallback={this.setEditedField}
                refreshCallback={this.mergeEditsUserInfo}
              />
            )}

            {this.state.editingField === 'pi-field-phone' && (
              <PhoneNumberEditor
                saveHandler={this.persistChangeToCustomerDetail}
                refreshCallback={this.mergeEditsUserInfo}
                editingCallback={this.setEditedField}
                accountData={customerData}
              />
            )}
            {this.state.editingField === 'pi-field-dob' && (
              <BirthdateEditor
                saveHandler={this.persistChangeToCustomerDetail}
                refreshCallback={this.mergeEditsUserInfo}
                editingCallback={this.setEditedField}
                accountDetails={this.props.accountDetails}
                accountData={customerData}
                birthDate={this.props.birthDate}
              />
            )}
            {this.state.editingField === 'pi-field-emergency-contact' && (
              <EmergencyContactEditor
                saveHandler={this.persistChangeToCustomerDetail}
                refreshCallback={this.mergeEditsEmergencyContact}
                editingCallback={this.setEditedField}
                details={details}
              />
            )}
            {this.state.editingField === 'lns-field-secqs' && (
              <SecurityQuestionsEditor
                securityQuestions={this.props.securityQuestions}
                userSecurityQuestions={usersOwnSecurityQuestions}
                refreshHandler={this.mergeEditsSecurityQs}
                setFocusOnBackAfterModalDismissed={this.setFocusOnBackAfterModalDismissed}
              />
            )}
            {this.state.editingField === 'lns-field-pin' && (
              <PINEditor
                customerEnrollment={this.state.enrollmentData}
                editingCallback={this.setEditedField}
                accountDetails={this.props.accountDetails}
                setFocusOnBackAfterModalDismissed={this.setFocusOnBackAfterModalDismissed}
              />
            )}
            {this.state.editingField === carnet_constants.SERVICES_EDITOR && (
              <ServicesEditor accountDetails={this.props.accountDetails} />
            )}
            {this.state.editingField === carnet_constants.VEHICLE_NICKNAME_EDITOR && (
              <VehicleNicknameEditor
                vehicleData={contextVehicle}
                saveHandler={this.saveChangeToContextVehicle}
                exitCallback={this.setEditedField}
                locale={this.props.locale}
              />
            )}

            {this.state.editingField === carnet_constants.FIND_DEALER && (
              <FindADealerNew
                userZip={customerData.address.zipCode || '48236'}
                vehicleData={contextVehicle}
                saveHandler={this.saveChangeToContextVehicle}
                locale={this.props.locale}
                setFocusOnBackAfterModalDismissed={this.setFocusOnBackAfterModalDismissed}
              />
            )}

            {this.state.editingField === carnet_constants.MARKETING_CONSENTS_EDITOR && <MarketingConsentsEditor />}

            {this.state.editingField === carnet_constants.WIFI_HOTSPOT_SETTINGS && (
              <WifiSettingsEditor locale={this.props.locale} />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getCustomerAccountDetails: actions.getCustomerAccountDetails,
        updateCustomerDetails: actions.updateCustomerDetails,
        saveAcctAndVehiclePrefs: actions.saveAcctAndVehiclePrefs,
        getCarnetCustomerStaticData: actions.getCarnetCustomerStaticData,
        // getUserInfo: actions.getUserInfo, // not necessary
        getUserSecurityQuestions: actions.getUserSecurityQuestions
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { carnetCustomerStaticData, customerAccountDetails = {}, userInfo = {}, userSecurityQuestions } = state;
  //let customerProfile = userInfo;

  // placeholders to avoid bad json parsing
  //customerProfile.info = customerProfile.info || {};
  customerAccountDetails.customerData = customerAccountDetails.customerData || {};
  customerAccountDetails.customerData.customer = customerAccountDetails.customerData.customer || {};

  // Grab locale data from languageProvider and set as a prop for the components in view
  let userLocale = getLocaleSync();
  let userCountry = userLocale.userCountry;
  let isCanadianUser = userCountry.toLowerCase() === 'ca';

  // originally we had two different (though very close) data sources for account customer info
  // however, that created issues, so now we technically just have one source but it is referenced in 2 different props
  // TODO clean up all child components to only use one prop as the single data source (rather than referencing two props that use data from the same microservice)
  let theCustomerData = { ...customerAccountDetails.customerData.customer };
  let countriesOptions = [
    { code: 'US', value: 'United States' },
    { code: 'CA', value: 'Canada' }
  ];

  if (carnetCustomerStaticData.countries && carnetCustomerStaticData.countries.length > 0) {
    countriesOptions = carnetCustomerStaticData.countries.map(item => {
      let val = item.countryDesc;
      let cd = item.countryCode;
      return { code: cd, value: val };
    });
  }

  let statesOptions = [];
  if (carnetCustomerStaticData.states) {
    statesOptions = carnetCustomerStaticData.states.map(item => {
      let val = item.stateDesc;
      let cd = item.stateCode;
      return { code: cd, value: val };
    });
  }

  let secQOptions = [];
  if (carnetCustomerStaticData.securityQuestions && carnetCustomerStaticData.securityQuestions.length > 0) {
    secQOptions = carnetCustomerStaticData.securityQuestions.map(question => {
      let val = question.questionText;
      let cd = question.questionId;
      return { code: cd, value: val };
    });
  }

  // use accountDetails birthDate everywhere used
  let birthDate = findIn('customerData.customer.birthDate', customerAccountDetails);

  let communicationLanguage = findIn('customerData.customer.localeCode', customerAccountDetails);

  let nxState = {
    customerData: theCustomerData,
    accountDetails: customerAccountDetails.customerData || {},
    birthDate,
    countries: countriesOptions,
    states: statesOptions,
    securityQuestions: secQOptions,
    userSecurityQuestions: userSecurityQuestions,
    isCanadianUser,
    communicationLanguage
  };

  //Needed for detecting the completion of a Save (update) for Toasts & UI indicators:
  if (
    (state.updateCustomerDetails && state.updateCustomerDetails.saveResult === true) ||
    state.updateCustomerDetails.saveResult === false
  ) {
    nxState['saveResult'] = state.updateCustomerDetails.saveResult;
  }

  return nxState;
};

export { AccountManagement };
export default connect(mapStateToProps, mapDispatchToProps)(AccountManagement);
