import React from 'react';
import carnet_constants from '../../../constants/carnet_constants';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { deleteDevicePairing } from '../providers/AccountAndSecurityProvider';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import '../../reusable_cmp_lib/ThemedModal/ThemedModal.scss';
import './AccountManagement.scss';
import { findIn } from '../../../util/utilityMethods';
import { setTitle } from '../../../providers/documentTitleProvider';

class VehicleInfo extends React.Component {
  constructor (props) {
    super(props); //props.tsp injects the vehicle TSP

    let pairedDevicesArr = this.props.pairedDevices || [];
    this.props.actions.getCarNickName();
    this.state = {
      editingField: null,
      pairedDevices: pairedDevicesArr,
      targetedDevice: null,
      userIsPrimary: false
    };

    this.getActiveEditingPane = this.getActiveEditingPane.bind(this);
  }

  componentDidMount = () => {
    setTitle('document_titles.account_management.vehicle_information');
    this.props.actions.getPreferredDealer();
    this.props.actions.getDealerUrls();
    let loadedNickName = this.props.carNickName || 'NOT AVAILABLE';
    let ctxVehicle = this.props.vehicleData || {};
    ctxVehicle = this.fortifyContextVehicle(ctxVehicle);
    console.log('After fortification, the context vehicle is now: ' + JSON.stringify(ctxVehicle));
    let primaryUser = isUserPrimary();
    this.setState({
      contextVehicle: ctxVehicle,
      stCarNickName: loadedNickName,
      userIsPrimary: primaryUser
    });
    if (this.props.focus) {
      try {
        document.getElementById('firstActiveFocus').focus();
      } catch (err) {
        console.log('element not found for autofocus');
      }
    }
  };

  fortifyContextVehicle = baseVehicleObject => {
    const vehicleEnrollments = this.props.enrollmentStatus.vehicleEnrollmentStatus;
    if (!vehicleEnrollments || vehicleEnrollments.length === 0) {
      console.log('No vehicle enrollments found in the enrollmentStatus data');
      return baseVehicleObject; //exit out, do nothing (no ancillary data)
    }
    //pluck the matching vehicle data by vehicleId:
    let enrollmentMatches = vehicleEnrollments.filter(item => {
      return item.vehicleId === baseVehicleObject.vehicleId;
    });

    if (enrollmentMatches && enrollmentMatches.length > 0) {
      let mergeReceptor = JSON.parse(JSON.stringify(baseVehicleObject));
      let auxVehicleData = enrollmentMatches[0];
      mergeReceptor.vin = mergeReceptor.vin || (auxVehicleData.vehicle && auxVehicleData.vehicle.vin) || '';
      return mergeReceptor;
    }
  };

  handleExitEditMode = () => {
    this.setState({ editingField: null });
  };

  closeDeletionModal = () => {
    this.setState({
      targetedDevice: null
    });
  };

  /**
   * Forward to the ENROLL page, or the UNENROLL page, based on the flag value
   */
  setVSCEnrollmentStatus = doEnroll => {
    let forwardingDestUrl = doEnroll ? '#/vsc-enroll' : '#/vsc-unenroll';
    window.location.assign(forwardingDestUrl);
    return;
  };

  targetPairedDeviceForDeletion = index => {
    const pairedDevices = this.state.pairedDevices || [];
    if (index < 0 || index >= pairedDevices.length) {
      console.error('Invalid index passed to targetPairedDeviceForDeletion: ' + index);
    }
    const device = pairedDevices[index];
    let target = { deviceIndex: index, device: device };
    this.setState({
      targetedDevice: target
    });
  };

  remoteTheDeletion = pId => {
    let delPayload = { pairingId: pId };
    deleteDevicePairing(delPayload).then(resp => {
      console.log('Successful remote call to delete this pairing: ' + JSON.stringify(delPayload));
    });
  };

  deleteTargetPairing = () => {
    let currentPairings = this.state.pairedDevices;
    if (this.state.targetedDevice) {
      let targetedDevice = this.state.targetedDevice;
      let pairingId = findIn('device.pairingId', targetedDevice);
      if (!pairingId) {
        console.log('Can not delete this device. Missing data.');
        return;
      }
      this.remoteTheDeletion(pairingId);
      const targetIndex = targetedDevice.deviceIndex;
      currentPairings.splice(targetIndex, 1);
    }
    this.setState({
      pairedDevices: currentPairings,
      targetedDevice: null
    });
  };

  editField (reference) {
    this.props.editingCallback(reference);
  }

  getActiveEditingPane () {
    if (this.state.editingField === null) {
      return <span></span>;
    }
  }

  getPreferredDealerName = () => {
    let arr = (Array.isArray(this.props.preferredDealerData) && this.props.preferredDealerData) || [];
    let dealer = arr.filter(filteredDealer => filteredDealer.preferredDealerType === 'SERVICE')[0] || {
      dealerDetails: {},
      emptyDefault: true
    };
    // couldn't find the 'SERVICE' dealer, just use whatever is present
    if (dealer.emptyDefault && arr.length) {
      dealer = arr[0];
    }
    let name = (dealer && dealer.dealerDetails && dealer.dealerDetails.name) || '';
    return name;
  };

  render () {
    this.translator = getLocaleTranslator();
    //the way to create a Deep Clone (nested props)
    let focusVehicle = JSON.parse(JSON.stringify(this.props.vehicleData)) || {};
    focusVehicle = this.fortifyContextVehicle(focusVehicle);
    const conciergeEnrolledStatus =
      this.props.accountDetails &&
      this.props.accountDetails.vehicleHealthConciergeEnrolled &&
      this.props.accountDetails.vehicleHealthConciergeEnrolled === true
        ? this.translator.t('acctmgmt_vi_enroll_status_indicator')
        : this.translator.t('acctmgmt_vi_notenrolled_status_indicator');

    let targetedDeviceName =
      this.state.targetedDevice && this.state.targetedDevice.device ? this.state.targetedDevice.device.phoneName : '';

    const datumNotInMicroserviceResponse = this.translator.t('acctmgmt_datum_absent_from_uservice_response');
    return (
      <div className='acctmgmt-contentpane'>
        <div id='ghost-wrapper' className='vehicle-info-parent'>
          <div className='contentpane-title'>
            <h2 id='firstActiveFocus' tabIndex={-1} className='h6'>
              {this.translator.t('acctmgmt_vi_pane_title')}
            </h2>
          </div>
          <div>
            <Row>
              <Col md='2'>
                <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_vi_field_nickname')}</span>
              </Col>
              <Col md='2'></Col>
              <Col md='6'>
                <span ref='pi-name-field' className='carnet-theme-field-value vi-data'>
                  {this.props.carNickName || ''}
                </span>
              </Col>
              <Col md='2'>
                <button
                  className='btn btn-link carnetLinkLight'
                  type='button'
                  onClick={e => this.editField(carnet_constants.VEHICLE_NICKNAME_EDITOR)}
                >
                  {this.translator.t('acctmgmt_pi_edit_link_label')}
                </button>
              </Col>
            </Row>
            <Row>
              <Col md='2'>
                <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_vi_field_vin')}</span>
              </Col>
              <Col md='2'></Col>
              <Col md='6'>
                <span className='carnet-theme-field-value vi-data'>{(focusVehicle && focusVehicle.vin) || ''}</span>
              </Col>
              <Col md='2'></Col>
            </Row>
            {this.props.tsp && this.props.tsp !== carnet_constants.TSP_VZT && (
              <Row>
                <Col md='2'>
                  <span className='carnet-theme-field-label'>
                    {this.translator.t('acctmgmt_vi_field_connectivity_carrier')}
                  </span>
                </Col>
                <Col md='2'></Col>

                <Col md='6'>
                  <span className='carnet-theme-field-value vi-data'>
                    {findIn('ocuSim.ocuSimProfile.mnoProvider', focusVehicle) === carnet_constants.MNO_PROVIDER_TMO && (
                      <>{this.translator.t('acctmgmt_vi_field_connectivity_carrier_tmo')}</>
                    )}
                    {findIn('ocuSim.ocuSimProfile.mnoProvider', focusVehicle) ===
                      carnet_constants.MNO_PROVIDER_VERIZON && (
                      <>{this.translator.t('acctmgmt_vi_field_connectivity_carrier_verizon')}</>
                    )}
                    {findIn('ocuSim.ocuSimProfile.mnoProvider', focusVehicle) ===
                      carnet_constants.MNO_PROVIDER_ROGERS && (
                      <>{this.translator.t('acctmgmt_vi_field_connectivity_carrier_rogers')}</>
                    )}
                  </span>
                </Col>

                <Col md='2'></Col>
              </Row>
            )}
            {/* IMEI shows only for non-VZT vehicles */}
            {this.props.tsp && this.props.tsp !== carnet_constants.TSP_VZT && (
              <Row>
                <Col md='2'>
                  <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_vi_field_imei')}</span>
                </Col>
                <Col md='2'></Col>
                <Col md='6'>
                  <span className='carnet-theme-field-value vi-data'>
                    {(focusVehicle && focusVehicle.ocuSim && focusVehicle.ocuSim.imei) ||
                      datumNotInMicroserviceResponse}
                  </span>
                </Col>
                <Col md='2'></Col>
              </Row>
            )}
            {this.props.tsp && this.props.tsp !== carnet_constants.TSP_VZT && (
              <Row>
                <Col md='2'>
                  <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_msisdn_label')}</span>
                </Col>
                <Col md='2'></Col>
                <Col md='6'>
                  <span className='carnet-theme-field-value vi-data'>
                    {(focusVehicle &&
                      focusVehicle.ocuSim &&
                      focusVehicle.ocuSim.ocuSimProfile &&
                      focusVehicle.ocuSim.ocuSimProfile.msisdn) ||
                      datumNotInMicroserviceResponse}
                  </span>
                </Col>
                <Col md='2'></Col>
              </Row>
            )}

            {/* Conditional-display Row (vehicle tsp) */}
            {this.props.tsp && this.props.tsp === carnet_constants.TSP_ATC && (
              <Row>
                <Col md='2'>
                  <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_vi_field_tz')}</span>
                </Col>
                <Col md='2'></Col>
                <Col md='6'>
                  <span className='carnet-theme-field-value  vi-data'>{this.props.vehicleTz || ''}</span>
                </Col>
                <Col md='2'></Col>
              </Row>
            )}

            {/* New Subscription Call Msg */}
            {this.props.tsp && this.props.tsp === carnet_constants.TSP_VZT && (
              <Row>
                <Col md='2'>
                  <span className='carnet-theme-field-label'></span>
                </Col>
                <Col md='2'></Col>
                <Col md='6'>
                  <div>
                    <span id='subscription-call-msg' class='carnet-theme-field-value vi-data'>
                      {this.translator.t('acctmgmt_subscription_call_msg')}
                    </span>
                  </div>
                  <div id='subscription-call-number'>{this.translator.t('acctmgmt_subscription_call_number')}</div>
                </Col>
                <Col md='2'></Col>
              </Row>
            )}

            <Row>
              <Col md='2'>
                <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_vi_field_psd')}</span>
              </Col>
              <Col md='2'></Col>
              <Col md='6'>
                <span className='carnet-theme-field-value vi-data'>{this.getPreferredDealerName()}</span>
              </Col>
              <Col md='2'>
                <button
                  className='btn btn-link carnetLinkLight'
                  type='button'
                  onClick={e => this.editField(carnet_constants.FIND_DEALER)}
                >
                  {this.translator.t('acctmgmt_change_label')}
                </button>
              </Col>
            </Row>

            {/* Paired Devices */}
            {this.props.tsp && this.props.tsp === carnet_constants.TSP_ATC && (
              <Row>
                <Col md='2'>
                  <span className='carnet-theme-field-label'>
                    {this.translator.t('acctmgmt_vi_paired_devices_field')}
                  </span>
                </Col>
                <Col md='2'>
                  {/* Conditional Display the Delete Paired Device MODAL */}
                  <Modal
                    size='sm'
                    centered={true}
                    isOpen={this.state.targetedDevice !== null}
                    className='carnet-sg-modal'
                  >
                    <ModalHeader>{this.translator.t('acctmgmt_modal_delete_paired')}</ModalHeader>
                    <ModalBody>
                      {this.translator.t('acctmgmt_vi_deletepaired_instrux_1') +
                        ' ' +
                        targetedDeviceName +
                        ' ' +
                        this.translator.t('acctmgmt_vi_deletepaired_instrux_2')}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className='modal-primary-btn'
                        onClick={this.closeDeletionModal}
                        color='modal_background_color'
                      >
                        {this.translator.t('cancel_btn_camel')}
                      </Button>
                      <Button
                        className='modal-secondary-btn'
                        color='modal-text-color'
                        onClick={this.deleteTargetPairing}
                      >
                        {this.translator.t('delete_label')}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md='8'>
                  <div className='editor-diatom-field'>
                    {/*  ITERATE PAIRED DEVICES HERE: */}
                    {this.state.pairedDevices.map((device, index) => {
                      let rowRef = 'paired-device-' + index;
                      return (
                        <Row className='borderless'>
                          <Col md='10'>
                            <div id={rowRef} className='vi-paired-device vi-data'>
                              <span className='paired-device-name'>{device.phoneName}</span>
                            </div>
                          </Col>
                          <Col md='2' className='delete-paired-device-alink-col'>
                            <span className='theme-updatelink'>
                              <a onClick={() => this.targetPairedDeviceForDeletion(index)}>
                                {this.translator.t('delete_label')}
                              </a>
                            </span>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            )}

            {/* TSP-conditional display row */}
            {this.props.tsp !== null &&
              this.props.tsp === 'ATC' &&
              this.props.isCanadian === false &&
              this.state.userIsPrimary === true && (
                <Row>
                  <Col md='2'>
                    <span className='carnet-theme-field-label'>
                      {this.translator.t('acctmgmt_vi_concierge_pilot_field')}
                    </span>
                  </Col>
                  <Col md='2'></Col>
                  <Col md='6'>
                    <div className='editor-diatom-field'>
                      <div id='paired-device-1' className='concierge-pilot-enrollment'>
                        <span id='concierge-pilot-enrolled-indicator'>{conciergeEnrolledStatus}</span>
                      </div>
                      <div id='paired-device-1' className='vi-paired-device vi-data'>
                        {this.props.accountDetails &&
                          this.props.accountDetails.vehicleHealthConciergeEnrolled === true && (
                            <span className='vi-data'>
                              {(this.props.accountDetails &&
                                this.props.accountDetails.customer &&
                                this.props.accountDetails.customer.vehicleHealthConciergeContact &&
                                this.props.accountDetails.customer.vehicleHealthConciergeContact.phone &&
                                this.props.accountDetails.customer.vehicleHealthConciergeContact.phone.number) ||
                                ''}
                            </span>
                          )}
                      </div>
                    </div>
                  </Col>
                  <Col md='2'>
                    {this.props.accountDetails &&
                      this.props.accountDetails.vehicleHealthConciergeEnrolled &&
                      this.props.accountDetails.vehicleHealthConciergeEnrolled === true && (
                        <span className='theme-updatelink'>
                          <a onClick={() => this.setVSCEnrollmentStatus(false)}>
                            {this.translator.t('unenroll_label')}
                          </a>
                        </span>
                      )}
                    {this.props.accountDetails && !this.props.accountDetails.vehicleHealthConciergeEnrolled && (
                      <span className='theme-updatelink'>
                        <a onClick={() => this.setVSCEnrollmentStatus(true)}>{this.translator.t('enroll_label')}</a>
                      </span>
                    )}
                  </Col>
                </Row>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getPreferredDealer: actions.getPreferredDealer,
        getDealerUrls: actions.getDealerUrls,
        getCarNickName: actions.getCarNickName
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { preferredDealerDetails = {}, dealerUrlsDetails, carNickNameInfo } = state;
  const loadedCarName =
    (carNickNameInfo && carNickNameInfo.carNickNameData && carNickNameInfo.carNickNameData.carNameDesc) || '';

  return {
    preferredDealerData: preferredDealerDetails.preferredDealerData,
    dealerUrlsData: dealerUrlsDetails.dealerUrlsData,
    carNickName: loadedCarName
  };
};

export { VehicleInfo };
export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfo);
