import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledAlert } from 'reactstrap';
import { TosManifestId, findTosTypeByManifestId, updateTOS } from '../../providers/GarageProvider';
import PrivacyPolicy from './PrivacyPolicy';
import MarketingConsent from './MarketingConsent';
import CarnetTos from './CarnetTos';
import './tosUpdate.scss';
import MyVwTos from './MyVWtos';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import { getLocaleSync } from '../../../../providers/languageProvider';

export default class TosAndPrivacyUpdates extends React.Component {
  static propTypes = {
    translator: PropTypes.object.isRequired,
    manifest: PropTypes.object.isRequired,
    onSuccess: PropTypes.func
  };

  state = {
    processing: false,
    alert: null
  };

  submitTos = async tosStatus => {
    const { id, name, tosVersion, vehicleId } = this.props?.manifest;

    if (!tosVersion) {
      this.setState(`TosVersion not found for ${name}`);

      return;
    }

    this.setState({ processing: true, alert: null });

    const tosType = findTosTypeByManifestId(id);

    try {
      await updateTOS(tosStatus, tosType, tosVersion, vehicleId);
      this.setState({ processing: false });
      this.props.onSuccess();
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.props.translator) });
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite'>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }
    return null;
  };

  render () {
    const translator = this.props.translator;
    const id = this.props.manifest?.id;
    const { locale } = getLocaleSync();

    return (
      <>
        {this.getAlerts()}
        {TosManifestId.MY_VW_TOS === id && (
          <MyVwTos
            translator={translator}
            manifest={this.props.manifest}
            onSubmit={this.submitTos}
            processing={this.state.processing}
            showAlert={message => this.setState({ alert: message })}
            locale={locale}
          />
        )}
        {TosManifestId.PRIVACY_POLICY === id && (
          <PrivacyPolicy
            translator={translator}
            manifest={this.props.manifest}
            onSubmit={this.submitTos}
            processing={this.state.processing}
            showAlert={message => this.setState({ alert: message })}
            locale={locale}
          />
        )}
        {TosManifestId.MARKETING_COMMUNICATIONS_CONSENT === id && (
          <MarketingConsent
            translator={translator}
            manifest={this.props.manifest}
            onSubmit={this.submitTos}
            processing={this.state.processing}
            showAlert={message => this.setState({ alert: message })}
            locale={locale}
          />
        )}
        {[TosManifestId.ADDNL_USER_TOS, TosManifestId.PRIM_USER_TOS].includes(id) && (
          <CarnetTos
            translator={translator}
            manifest={this.props.manifest}
            onSubmit={this.submitTos}
            processing={this.state.processing}
            showAlert={message => this.setState({ alert: message })}
            locale={locale}
          />
        )}
      </>
    );
  }
}
