import React from 'react';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import { UncontrolledAlert } from 'reactstrap';
import {
  submitTosConcierge,
  findApplicableTos,
  TOS_TYPE,
  TOS_STATUS,
  PHONE_TYPE
} from '../providers/SelfEnrollmentProvider';
import { US_PHONE_NUMBER_PATTERN, formatPhoneNumber, digitsOnly } from '../CarnetHome';

class TosConcierge extends React.Component {
  state = {
    alert: null,
    processing: false,
    tosVersion: null,
    tosStatus: null,
    phone: null,
    phoneType: null
  };

  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    this.setState({ processing: true });
    try {
      let { tosVersion, tosContent } = await findApplicableTos(TOS_TYPE.VEHICLE_HEALTH_CONCIERGE);
      this.setState({ processing: false, tosVersion, tosContent });
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  toggleTosStatus = () => {
    if (this.state.tosStatus === TOS_STATUS.ACCEPT) {
      this.setState({ tosStatus: TOS_STATUS.DECLINE });
      return;
    }

    this.setState({ tosStatus: TOS_STATUS.ACCEPT });
  };

  skipTos = () => {
    this.props.afterSubmit();
  };

  submitTos = async () => {
    this.setState({ processing: true, alert: null });

    try {
      let response = await submitTosConcierge(
        this.state.tosVersion,
        this.state.tosStatus,
        this.state.phone,
        this.state.phoneType
      );
      this.setState({ processing: false });

      this.props.afterSubmit(this.state.tosStatus, response);
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  render () {
    return (
      <>
        <div className='row m-0'>
          <div className='col-md-1'></div>
          <div className='col-md-10 text-left mx-auto mb-5 p-0'>
            <div className='row m-0 p-0'>
              <div className='col-12'>
                <h1 className='text-left float-left mr-3'>{this.translator.t('tos_vhc.page_title')}</h1>
                {this.getAlerts()}
              </div>
            </div>
            <div className='row m-0 mt-md-4'>
              <div className='col-md-4 p-4'>
                <div className='conciergePhoneScreen'></div>
              </div>
              <div className='col-md-8 p-4'>
                <h4>{this.translator.t('tos_vhc.page_subtitle_1')}</h4>
                <p>
                  {this.translator.t('tos_vhc.main_desc_1_part_1')}
                  <sup>{this.translator.t('tos_vhc.number_one')}</sup> {this.translator.t('tos_vhc.main_desc_1_part_2')}
                  <sup>{this.translator.t('tos_vhc.number_two')}</sup>
                </p>
                <p>
                  {this.translator.t('tos_vhc.main_desc_2_part_1')}
                  <sup>{this.translator.t('tos_vhc.number_two')}</sup> {this.translator.t('tos_vhc.main_desc_2_part_2')}
                </p>
                <p>{this.translator.t('tos_vhc.main_desc_3')}</p>
                <ol className='small pl-3'>
                  <li>{this.translator.t('tos_vhc.disclaimer_1')}</li>
                  <li>{this.translator.t('tos_vhc.disclaimer_2')}</li>
                </ol>
              </div>
            </div>
            <div className='row justify-content-center mb-5'>
              <div className='col-md-7 text-left px-md-4'>
                <h2 className='font-weight-normal'>{this.translator.t('tos_vhc.page_subtitle_2_part_1')}</h2>
                <h2>{this.translator.t('tos_vhc.page_subtitle_2_part_2')}</h2>
                <br />
                <h5 className='mb-0 mt-3 leftIcon iconAssistance'>
                  {this.translator.t('tos_vhc.assistance_with_service_scheduling')}
                </h5>
                <span className='small'>{this.translator.t('tos_vhc.assistance_with_service_scheduling_desc')}</span>

                <h5 className='mb-0 mt-3 leftIcon iconScheduling'>{this.translator.t('tos_vhc.easy_scheduling')}</h5>
                <span className='small'>
                  {this.translator.t('tos_vhc.easy_scheduling_desc')}
                  <br />
                  <br />
                  <span className='small'>{this.translator.t('tos_vhc.short_note')}</span>
                </span>
              </div>
              <div className='col-md-5 py-3'>
                <div className='conciergeAssistance'></div>
              </div>
            </div>

            <div className='row justify-content-center m-0'>
              <div className='col-md-8 px-md-5'>
                <p>{this.translator.t('tos_vhc.tos_note')}</p>

                <input
                  type='tel'
                  className='form-control cwp ml-0 mr-sm-3 float-left'
                  pattern={US_PHONE_NUMBER_PATTERN}
                  maxLength={14}
                  placeholder={this.translator.t('tos_vhc.preferred_phone_number')}
                  value={formatPhoneNumber(this.state.phone)}
                  onChange={e => this.setState({ phone: digitsOnly(e.currentTarget.value) })}
                />

                <select
                  className='form-control cwp'
                  onChange={e => this.setState({ phoneType: e.currentTarget.value })}
                >
                  <option value=''>{this.translator.t('tos_vhc.phone_type')}</option>
                  <option value={PHONE_TYPE.HOME_PHONE_NUM}>{this.translator.t('tos_vhc.home_phone')}</option>
                  <option value={PHONE_TYPE.BUS_PHONE_NUM}>{this.translator.t('tos_vhc.work_phone')}</option>
                  <option value={PHONE_TYPE.CELL_PHONE_NUM}>{this.translator.t('tos_vhc.cell_phone')}</option>
                </select>

                <br />
                <span
                  className={TOS_STATUS.ACCEPT === this.state.tosStatus ? 'cwpCheckbox cwpCheckboxOn' : 'cwpCheckbox'}
                  onClick={this.toggleTosStatus}
                >
                  {this.translator.t('tos_vhc.enroll_in_vsc_pilot')}
                </span>

                <div className='row mx-0 my-5'>
                  <div className='col-6 pl-0'>
                    <button className='cwpSecondary float-right' onClick={this.skipTos}>
                      {this.translator.t('tos_vhc.skip_step')}
                    </button>
                  </div>
                  <div className='col-6 pr-0'>
                    <button
                      className='cwp'
                      onClick={this.submitTos}
                      disabled={
                        this.state.processing ||
                        this.state.tosStatus !== TOS_STATUS.ACCEPT ||
                        !this.state.phone ||
                        !this.state.phoneType ||
                        this.state.phone.length < 10
                      }
                    >
                      {this.translator.t('tos_vhc.continue')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>
      </>
    );
  }
}

export default TosConcierge;
